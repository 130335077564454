import React from 'react';

import { DataFrame, FieldType, TimeRange } from '@grafana/data';
import {
  behaviors,
  SceneDataLayerSet,
  SceneDataTransformer,
  SceneQueryRunner,
  SceneTimeRangeLike,
  VizPanel,
} from '@grafana/scenes';
import { DashboardCursorSync, DataQuery, DataTopic, TooltipDisplayMode } from '@grafana/schema';
import { IconButton, Stack } from '@grafana/ui';

import { ExploreButton } from 'components/ExploreButton';
import { Analysis, LabelInput, MetricQueryInput } from 'types';
import { filterByLabelsTransformation, toTimeRange } from 'utils';

import { CustomAnnotationsDataLayer } from '../../../components/CustomAnnotations';

interface DataQueryWithExpr extends DataQuery {
  expr?: string;
}

export function getInvestigationTimeline(
  metricInputs: MetricQueryInput[],
  labelInputs: LabelInput[],
  timeRange: SceneTimeRangeLike,
  initialTimeRange: TimeRange,
  datasourceUid: string,
  analyses?: Analysis[]
): VizPanel {
  // add all the metric query inputs to the query runner
  const queries = metricInputs.map(({ metric }, i) => {
    const metricWithExpr = metric as DataQueryWithExpr;
    return {
      ...metricWithExpr,
      datasource: {
        ...metricWithExpr.datasource,
        uid: (metricWithExpr.datasource?.uid ?? '') === '' ? datasourceUid : metricWithExpr.datasource?.uid,
      },
      refId: String.fromCharCode(65 + i),
    };
  });

  // Add annotations to event timeline if analyses are available
  const annotationsLayers =
    analyses !== undefined
      ? [
          new CustomAnnotationsDataLayer({
            name: 'Custom annotations populated from analysis events',
            timerange: toTimeRange({ from: timeRange.state.from, to: timeRange.state.to }, 'browser'),
            dataframe: analyses.filter((x) => x.result.events?.length ?? 0 > 0).flatMap(analysisEventsFrame),
          }),
        ]
      : [];

  const queryRunner = new SceneQueryRunner({
    queries,
    $data: new SceneDataLayerSet({ layers: annotationsLayers }),
    $behaviors: [new behaviors.CursorSync({ key: 'sync', sync: DashboardCursorSync.Crosshair })],
  });

  // Transformations are used to filter resulting series by labels
  // present on the investigation request data.
  // In these lines we are adding a transformation for each metric-query input.
  const transformations = metricInputs.map(({}, i) =>
    filterByLabelsTransformation({ [String.fromCharCode(65 + i)]: labelInputs })
  );
  const transformed = new SceneDataTransformer({ $data: queryRunner, transformations: transformations });

  return new VizPanel({
    pluginId: 'timeseries',
    title: 'Investigation timeline',
    description: 'Metric(s) from investigation source annotated with check results',
    headerActions: (
      <Stack alignItems="center" gap={1}>
        <IconButton
          name="history-alt"
          aria-label="Reset time range"
          tooltip="Reset time range"
          onClick={() => timeRange.onTimeRangeChange(initialTimeRange)}
        />
        <ExploreButton
          queries={queries.map((query) => query.expr ?? '')}
          datasourceUid={datasourceUid}
          timeRange={toTimeRange({ from: timeRange.state.from, to: timeRange.state.to }, 'browser')}
        />
      </Stack>
    ),
    options: {
      legend: {
        showLegend: false,
      },
      tooltip: {
        mode: TooltipDisplayMode.Single,
      },
    },
    fieldConfig: {
      defaults: {
        custom: { fillOpacity: 6 },
      },
      overrides: [],
    },
    $data: transformed,
    extendPanelContext: (vizpanel, ctx) => {
      ctx.canEditAnnotations = () => {
        return false;
      };
      ctx.canDeleteAnnotations = () => {
        return false;
      };
      return ctx;
    },
  });
}

function analysisEventsFrame(analysis: Analysis): DataFrame {
  const time: number[] = [];
  const text: Array<string | undefined> = [];
  const dashboardIds: number[] = [];
  analysis.result?.events?.forEach((event) => {
    if (event.description === undefined || event.description?.length === 0) {
      return;
    }
    const ts = new Date(event.startTime);
    time.push(ts.getTime());
    text.push(event.description);
    dashboardIds.push(1);
  });
  return {
    meta: {
      dataTopic: DataTopic.Annotations,
    },
    fields: [
      {
        name: 'time',
        type: FieldType.time,
        config: {},
        values: time,
      },
      {
        name: 'text',
        type: FieldType.string,
        config: {},
        values: text,
      },
      {
        name: 'dashboardUID',
        type: FieldType.number,
        config: {},
        values: dashboardIds,
      },
    ],
    length: time.length,
  };
}
