import { DataFrame, DataTopic, LoadingState, PanelData, TimeRange } from '@grafana/data';
import { SceneDataLayerBase, SceneDataLayerProvider, SceneDataLayerProviderState } from '@grafana/scenes';

export interface AnnotationsDataLayerState extends SceneDataLayerProviderState {
  dataframe: DataFrame[];
  timerange: TimeRange;
}

// CustomAnnotationsDataLayer is a custom data layer that is used to display
// annotations on a timeline. It takes a timerange and a Dataframe of annotations
// as inputs.
//
// Inspired by the following sample from the Scenes repo:
// https://github.com/grafana/scenes/blob/d921200506a3508e19292f9fb7a68efb8d4e98c1/packages/scenes/src/querying/layers/annotations/AnnotationsDataLayer.ts
//
export class CustomAnnotationsDataLayer
  extends SceneDataLayerBase<AnnotationsDataLayerState>
  implements SceneDataLayerProvider
{
  public topic = DataTopic.Annotations;

  public constructor(initialState: AnnotationsDataLayerState) {
    super({ isEnabled: true, ...initialState });
  }

  onEnable(): void {}
  onDisable(): void {}

  public runLayer() {
    const { dataframe, timerange } = this.state;
    try {
      const events: DataFrame[] = dataframe ?? [];
      const data: PanelData = {
        series: events,
        state: LoadingState.Done,
        timeRange: timerange,
      };

      this.publishResults(data);
    } catch (e) {
      console.error('AnnotationsDataLayer error', e);
    }
  }
}
