import { PluginExtensionComponentConfig } from '@grafana/data';
import { config } from '@grafana/runtime';

import { SiftModalContext } from 'projects/Sift/SiftModal'; // SiftModal
import { tryGte } from 'utils';

import { SiftRunModalProps } from '../projects/Sift/RunSiftModal';
import { CreateInvestigationModal } from './components';
import { RunSiftModalWrapper } from './components/RunSiftModalWrapper';
import { SiftAnalysisModal } from './components/SiftAnalysisModal';
import {
  AlertInstancePluginLinkConfig,
  AppO11yOperationPluginLinkConfig,
  AppO11yServicePluginLinkConfig,
  CommandPalettePluginLinkConfig,
  ExplorePluginLinkConfig,
  ExtensionParams,
  IncidentRunSiftLinkConfig,
  K8sClusterPluginLinkConfig,
  K8sNamespacePluginLinkConfig,
  K8sPodPluginLinkConfig,
  K8sWorkloadPluginLinkConfig,
  NewPluginExtensionPoints,
  OnCallPluginLinkConfig,
  PanelPluginLinkConfig,
  PluginExtensionPoints,
  SiftPanelPluginLinkConfig,
} from './types';

const siftExtensionParams: ExtensionParams = {
  title: 'Run investigation',
  description: 'Investigate using Grafana Sift',
};

if (tryGte(config.buildInfo.version, '10.0.4')) {
  siftExtensionParams.category = 'Sift';
  siftExtensionParams.icon = 'search';
}

export const panelExtensionLinks: PanelPluginLinkConfig[] = [
  {
    ...siftExtensionParams,
    extensionPointId: PluginExtensionPoints.DashboardPanelMenu,
    onClick: (_, { context, openModal }): void => {
      const contextWrapper = { context, type: PluginExtensionPoints.DashboardPanelMenu } as const;
      openModal({
        title: 'New investigation',
        body: ({ onDismiss }) => CreateInvestigationModal({ contextWrapper, onDismiss }),
      });
    },
  },
];

export const exploreExtensionLinks: ExplorePluginLinkConfig[] = [
  {
    ...siftExtensionParams,
    extensionPointId: PluginExtensionPoints.ExploreToolbarAction,
    onClick: (_, { context, openModal }): void => {
      const contextWrapper = { context, type: PluginExtensionPoints.ExploreToolbarAction } as const;
      openModal({
        title: 'New investigation',
        body: ({ onDismiss }) => CreateInvestigationModal({ contextWrapper, onDismiss }),
      });
    },
  },
];

export const alertingExtensionLinks: AlertInstancePluginLinkConfig[] = [
  {
    ...siftExtensionParams,
    extensionPointId: NewPluginExtensionPoints.AlertInstanceAction,
    onClick: (_, { context, openModal }): void => {
      const contextWrapper = { context, type: NewPluginExtensionPoints.AlertInstanceAction } as const;
      openModal({
        title: 'New investigation',
        body: ({ onDismiss }) => CreateInvestigationModal({ contextWrapper, onDismiss }),
      });
    },
  },
];

export const onCallAlertGroupExtensionLinks: OnCallPluginLinkConfig[] = [
  {
    ...siftExtensionParams,
    extensionPointId: NewPluginExtensionPoints.OnCallAlertGroupAction,
    onClick: (_, { context, openModal }): void => {
      const contextWrapper = { context, type: NewPluginExtensionPoints.OnCallAlertGroupAction } as const;
      openModal({
        title: 'New investigation',
        body: ({ onDismiss }) => CreateInvestigationModal({ contextWrapper, onDismiss }),
      });
    },
  },
];

export const commandPaletteExtensionLinks: CommandPalettePluginLinkConfig[] = [
  {
    ...siftExtensionParams,
    extensionPointId: NewPluginExtensionPoints.CommandPalette,
    onClick: (_, { openModal }): void => {
      const contextWrapper = { context: {}, type: NewPluginExtensionPoints.CommandPalette } as const;
      openModal({
        title: 'New investigation',
        body: ({ onDismiss }) => CreateInvestigationModal({ contextWrapper, onDismiss }),
      });
    },
  },
];

const k8sClusterExtensionLink: K8sClusterPluginLinkConfig = {
  ...siftExtensionParams,
  title: 'Run Sift investigation',
  extensionPointId: NewPluginExtensionPoints.K8sClusterAction,
  onClick: (_, { context, openModal }): void => {
    const contextWrapper = { context, type: NewPluginExtensionPoints.K8sClusterAction } as const;
    openModal({
      title: 'New investigation',
      body: ({ onDismiss }) => CreateInvestigationModal({ contextWrapper, onDismiss }),
    });
  },
};
const k8sNamespaceExtensionLink: K8sNamespacePluginLinkConfig = {
  ...siftExtensionParams,
  title: 'Run Sift investigation',
  extensionPointId: NewPluginExtensionPoints.K8sNamespaceAction,
  onClick: (_, { context, openModal }): void => {
    const contextWrapper = { context, type: NewPluginExtensionPoints.K8sNamespaceAction } as const;
    openModal({
      title: 'New investigation',
      body: ({ onDismiss }) => CreateInvestigationModal({ contextWrapper, onDismiss }),
    });
  },
};
const k8sWorkloadExtensionLink: K8sWorkloadPluginLinkConfig = {
  ...siftExtensionParams,
  title: 'Run Sift investigation',
  extensionPointId: NewPluginExtensionPoints.K8sWorkloadAction,
  onClick: (_, { context, openModal }): void => {
    const contextWrapper = { context, type: NewPluginExtensionPoints.K8sWorkloadAction } as const;
    openModal({
      title: 'New investigation',
      body: ({ onDismiss }) => CreateInvestigationModal({ contextWrapper, onDismiss }),
    });
  },
};
const k8sPodExtensionLink: K8sPodPluginLinkConfig = {
  ...siftExtensionParams,
  title: 'Run Sift investigation',
  extensionPointId: NewPluginExtensionPoints.K8sPodAction,
  onClick: (_, { context, openModal }): void => {
    const contextWrapper = { context, type: NewPluginExtensionPoints.K8sPodAction } as const;
    openModal({
      title: 'New investigation',
      body: ({ onDismiss }) => CreateInvestigationModal({ contextWrapper, onDismiss }),
    });
  },
};
export const k8sExtensionLinks = [
  k8sClusterExtensionLink,
  k8sNamespaceExtensionLink,
  k8sWorkloadExtensionLink,
  k8sPodExtensionLink,
];

export const siftPanelLink: SiftPanelPluginLinkConfig = {
  ...siftExtensionParams,
  title: 'Run Sift investigation',
  extensionPointId: NewPluginExtensionPoints.SiftPanelRunInvestigation,
  onClick: (_, { context, openModal }): void => {
    const contextWrapper = { context, type: NewPluginExtensionPoints.SiftPanelRunInvestigation } as const;
    openModal({
      title: 'New investigation',
      body: ({ onDismiss }) => CreateInvestigationModal({ contextWrapper, onDismiss }),
    });
  },
};

const appO11yServiceExtensionLink: AppO11yServicePluginLinkConfig = {
  ...siftExtensionParams,
  title: 'Run Sift investigation',
  extensionPointId: NewPluginExtensionPoints.AppO11yServiceAction,
  onClick: (_, { context, openModal }): void => {
    const contextWrapper = { context, type: NewPluginExtensionPoints.AppO11yServiceAction } as const;
    openModal({
      title: 'New investigation',
      body: ({ onDismiss }) => CreateInvestigationModal({ contextWrapper, onDismiss }),
    });
  },
};
const appO11yOperationExtensionLink: AppO11yOperationPluginLinkConfig = {
  ...siftExtensionParams,
  title: 'Run Sift investigation',
  extensionPointId: NewPluginExtensionPoints.AppO11yOperationAction,
  onClick: (_, { context, openModal }): void => {
    const contextWrapper = { context, type: NewPluginExtensionPoints.AppO11yOperationAction } as const;
    openModal({
      title: 'New investigation',
      body: ({ onDismiss }) => CreateInvestigationModal({ contextWrapper, onDismiss }),
    });
  },
};
export const appO11yExtensionLinks = [appO11yServiceExtensionLink, appO11yOperationExtensionLink];

export const incidentRunSiftLink: IncidentRunSiftLinkConfig[] = [
  {
    ...siftExtensionParams,
    extensionPointId: NewPluginExtensionPoints.IncidentRunSiftModal,
    onClick: (_, { openModal }): void => {
      const contextWrapper = { context: {}, type: NewPluginExtensionPoints.IncidentRunSiftModal } as const;
      openModal({
        title: 'New investigation',
        body: ({ onDismiss }) => CreateInvestigationModal({ contextWrapper, onDismiss }),
      });
    },
  },
];

export const siftAnalysisModals: Array<Omit<PluginExtensionComponentConfig<SiftModalContext>, 'type'>> = [
  {
    title: 'Sift analysis modal',
    description: 'View details of a Sift analysis',
    extensionPointId: NewPluginExtensionPoints.IncidentAnalysisModal,
    component: SiftAnalysisModal,
  },
  {
    title: 'Sift analysis modal',
    description: 'View details of a Sift analysis',
    extensionPointId: 'plugins/grafana-sift-panel/view-analysis',
    component: SiftAnalysisModal,
  },
];

export const siftRunModal: Omit<PluginExtensionComponentConfig<SiftRunModalProps>, 'type'> = {
  title: 'Start an investigation',
  description: 'Start a Sift investigation',
  extensionPointId: NewPluginExtensionPoints.IncidentRunSiftModal,
  component: RunSiftModalWrapper,
};
