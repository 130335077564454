import { ComponentClass } from 'react';

import { AppPlugin, AppRootProps } from '@grafana/data';

import { MlAppSettings } from 'types';

import { App } from './App';
import { configureAppExtensions } from './extensions';

export const plugin = configureAppExtensions(
  new AppPlugin<MlAppSettings>().setRootPage(App as unknown as ComponentClass<AppRootProps<MlAppSettings>, unknown>)
);
