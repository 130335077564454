import React, { useMemo } from 'react';

import { DashboardCursorSync, TimeRange } from '@grafana/data';
import {
  behaviors,
  EmbeddedScene,
  SceneControlsSpacer,
  SceneFlexItem,
  SceneFlexLayout,
  SceneTimePicker,
  SceneTimeRange,
} from '@grafana/scenes';
import { LinkButton, Stack, Text } from '@grafana/ui';

import { SceneDrilldown } from 'components/SceneDrillDown/SceneDrillDown';
import { useScene } from 'hooks';
import { SiftModalData } from 'types';

import { DrilldownScene } from './Drilldown';
import { ErroredTab } from './ErroredTab';
import { OOMKilledTab } from './OOMKilledTab';
import { SceneFlexLayoutWithTitle } from './SceneFlexLayoutWithTitle';
import { Errored, KubeCrashesDetails, LegacyOOMKilled, OOMKilled, TabID, WorkloadCrashes } from './types';
import { workloadKubernetesMonitoringURL } from './utils';
import { WorkloadCard } from './WorkloadCard';

function isLegacyOOMKilled(obj: LegacyOOMKilled | OOMKilled[]): obj is LegacyOOMKilled {
  return obj.length > 0 && !('hasProfilingData' in obj[0]);
}

function transformLegacyOOMKilled(legacyOOMKilled: LegacyOOMKilled): OOMKilled[] {
  return legacyOOMKilled.map((labels) => ({
    labels,
    hasProfilingData: false,
  }));
}

const KubeCrashes = ({ analysis, investigation, datasources }: SiftModalData): React.ReactElement => {
  const details = (analysis.result?.details || {
    OOMKilled: [],
    Errored: [],
  }) as unknown as KubeCrashesDetails;

  if (isLegacyOOMKilled(details.OOMKilled)) {
    details.OOMKilled = transformLegacyOOMKilled(details.OOMKilled);
  }

  const lokiUid = datasources.lokiDatasource.uid;
  const prometheusUid = datasources.prometheusDatasource.uid;
  const pyroscopeUid = datasources.pyroscopeDatasource?.uid ?? '';
  const timeRange = investigation.timeRange;

  if (!analysis.result.successful) {
    return <div>Error running analysis: {analysis.result.message}</div>;
  }

  if (!analysis.result.interesting) {
    return <div>No Crashed Pods Found</div>;
  }

  const groupByClusterNamespace = (crashes: { Errored: Errored[]; OOMKilled: OOMKilled[] }) => {
    const grouped = new Map<string, { errored: Errored[]; oomkilled: OOMKilled[] }>();
    for (const errored of crashes.Errored) {
      const { cluster, namespace } = errored.labels;
      const key = JSON.stringify({ cluster, namespace });
      if (!grouped.has(key)) {
        grouped.set(key, { errored: [], oomkilled: [] });
      }
      grouped.get(key)!.errored.push(errored);
    }
    for (const oomkilled of crashes.OOMKilled) {
      const { cluster, namespace } = oomkilled.labels;
      const key = JSON.stringify({ cluster, namespace });
      if (!grouped.has(key)) {
        grouped.set(key, { errored: [], oomkilled: [] });
      }
      grouped.get(key)!.oomkilled.push(oomkilled);
    }
    return grouped;
  };

  return (
    <KubeCrashesInner
      analysisId={analysis.id}
      // @ts-expect-error
      grouped={groupByClusterNamespace(details as KubeCrashesDetails)}
      lokiUid={lokiUid}
      promUid={prometheusUid}
      pyroscopeUid={pyroscopeUid}
      timeRange={timeRange}
    />
  );
};

interface KubeCrashesInnerProps {
  analysisId: string;
  grouped: Map<string, { errored: Errored[]; oomkilled: OOMKilled[] }>;
  timeRange: TimeRange;
  promUid: string;
  pyroscopeUid: string;
  lokiUid: string;
}

interface DrilldownOpts {
  tab?: TabID;
}

function getOuterChildren(
  crashes: WorkloadCrashes[],
  timeRange: TimeRange,
  promUid: string,
  lokiUid: string,
  setDrilldownIdx: (idx: number, opts: DrilldownOpts) => void
): SceneFlexItem[] {
  return [
    ...crashes.map(
      (workload, i) =>
        new SceneFlexItem({
          body: new WorkloadCard({
            workload,
            timeRange,
            promUid,
            lokiUid,
            onDrilldown: (tab?: TabID) => setDrilldownIdx(i, { tab }),
          }),
          height: '150px',
          width: '300px',
          ySizing: 'content',
        })
    ),
  ];
}

function KubeCrashesInner({
  analysisId,
  grouped,
  timeRange,
  promUid,
  lokiUid,
  pyroscopeUid,
}: KubeCrashesInnerProps): React.ReactElement {
  const entries = useMemo(() => Array.from(grouped.entries()), [grouped]);
  // Show a drilldown for each cluster/namespace.
  const getScene = () => {
    const drilldowns = entries.map(([clusterNamespace, results]) => {
      const { cluster, namespace } = JSON.parse(clusterNamespace);

      const groupByWorkload = (crashes: Array<Errored | OOMKilled>): Map<string, typeof crashes> => {
        const grouped = new Map<string, Array<Errored | OOMKilled>>();
        for (const crash of crashes) {
          const workload = crash.labels.workload;
          if (!grouped.has(workload)) {
            // TODO: Deal with non-workload pods
            grouped.set(workload, []);
          }
          grouped.get(workload)!.push(crash);
        }
        return grouped;
      };
      // const errors = groupByWorkload(firstResults.errored).map(item => ({ type: 'errored', item }));
      const errors: Map<string, Errored[]> = groupByWorkload(results.errored);
      // @ts-ignore
      const oomkilled: Map<string, OOMKilled[]> = groupByWorkload(results.oomkilled);
      const crashes: Map<string, WorkloadCrashes> = new Map();
      for (const [name, errs] of errors) {
        if (!crashes.has(name)) {
          crashes.set(name, { name, cluster, namespace, errored: errs, oomkilled: [] });
        } else {
          crashes.get(name)!.errored = errs;
        }
      }
      for (const [name, ooms] of oomkilled) {
        if (!crashes.has(name)) {
          crashes.set(name, { name, cluster, namespace, errored: [], oomkilled: ooms });
        } else {
          crashes.get(name)!.oomkilled = ooms;
        }
      }
      const crashesArr = Array.from(crashes.values());
      crashesArr.sort((a, b) => a.name.localeCompare(b.name));
      return new SceneDrilldown<DrilldownOpts>({
        // Outer view.
        outerLayout: new SceneFlexLayoutWithTitle({
          title: `${cluster} / ${namespace}`,
          flexLayout: new SceneFlexLayout({
            direction: 'row',
            children: [],
          }),
          // This will be computed by the getOuterChildren function.
          children: [],
        }),
        getOuterChildren: (setDrilldownIdx) =>
          // the outer children
          getOuterChildren(crashesArr, timeRange, promUid, lokiUid, setDrilldownIdx),
        drilldownLayout: new SceneFlexLayout({
          // This will be computed by the getDrilldownChildren function.
          direction: 'column',
          height: '100%',
          width: '100%',
          children: [],
        }),
        getDrilldownChildren: (idx, opts) => {
          const workload = crashesArr[idx];
          return [getDrilldownScene(workload, lokiUid, promUid, pyroscopeUid, timeRange, opts?.tab)];
        },
        getDrilldownTitle: (idx) => {
          const { cluster, namespace, name } = crashesArr[idx];
          const title = (
            <Text element="h4">
              {cluster} / {namespace} / {name}
            </Text>
          );
          const url = workloadKubernetesMonitoringURL(crashesArr[idx], timeRange, promUid);
          if (url === undefined) {
            return title;
          }
          return (
            <Stack alignItems="center" gap={2}>
              {title}
              <LinkButton
                size="md"
                // @ts-ignore
                icon="kubernetes"
                target="_blank"
                href={url}
                tooltip="View workload in Kubernetes Monitoring"
                variant="secondary"
              />
            </Stack>
          );
        },
      });
    });

    return new EmbeddedScene({
      $behaviors: [new behaviors.CursorSync({ key: 'kube-crashes', sync: DashboardCursorSync.Crosshair })],
      controls: [new SceneControlsSpacer(), new SceneTimePicker({})],
      $timeRange: new SceneTimeRange({ value: timeRange }),
      body: new SceneFlexLayout({
        direction: 'column',
        children: drilldowns,
      }),
    });
  };

  // Cache the scene using the analysis ID as the key, so that
  // the data isn't refetched if the analysis is closed and re-opened.
  const scene = useScene(getScene, analysisId);
  return <scene.Component model={scene} />;
}

function getDrilldownScene(
  workload: WorkloadCrashes,
  lokiUid: string,
  promUid: string,
  pyroscopeUid: string,
  timeRange: TimeRange,
  tab?: TabID
): SceneFlexItem {
  return new SceneFlexItem({
    body: new DrilldownScene({
      workload,
      activeTab: tab,
      errorTabScene: new ErroredTab({ workload, lokiUid, promUid, timeRange }),
      oomKilledTabScene: new OOMKilledTab({ workload, lokiUid, promUid, pyroscopeUid, timeRange }),
    }),
    height: '100%',
  });
}

export { KubeCrashes };
