import React, { Dispatch, SetStateAction } from 'react';
import { Control, Controller, FieldErrors, UseFormRegister } from 'react-hook-form';

import { InlineField, Select, VerticalGroup } from '@grafana/ui';

import { css } from '@emotion/css';

import { Details } from 'components/Details';
import { InlineNumberAndUnitsField } from 'components/FormFields/InlineNumberAndUnitsField';
import { useFeature } from 'hooks';
import { FullFormModel, Model, ModelId, TimeUnit } from 'types';
import { useModelSelectOptions } from 'utils';

import ShowHyperparameterForm from './ShowHyperparameterForm';

export const intervalTooltip = `Sampling interval/frequency of the training data. The shorter the interval, the more data the model is trained with. A larger interval will speed up training and prediction but the final result may be less precise.

This should be a multiple of the original query's data source interval, to avoid training with interpolated data.

For example, if the data source of the original query is sampled every 30 seconds, this value should be a multiple of 30.`;

interface AlgorithmOptionsProps {
  control: Control<FullFormModel>;
  errors: FieldErrors<FullFormModel>;
  register: UseFormRegister<FullFormModel>;
  availableModels: readonly Model[];
  selectedModelId: ModelId | null;
  setSelectedModelId: Dispatch<SetStateAction<ModelId | null>>;
  holidays: string[];
}

export function AlgorithmOptions({
  control,
  errors,
  register,
  availableModels,
  selectedModelId,
  setSelectedModelId,
  holidays,
}: AlgorithmOptionsProps): JSX.Element | null {
  const augursEnabled = useFeature('AugursMSTL');
  const models = availableModels.filter((model) => augursEnabled || model.id !== 'grafana_augurs_mstl_0_1_0');
  const options = useModelSelectOptions(models, selectedModelId);
  return (
    <>
      <InlineField labelWidth={35} label="Training algorithm" grow={true} tooltip="Choose the ML algorithm to use">
        <VerticalGroup>
          <Controller
            name="parameters.id"
            control={control}
            render={({ field }) => (
              <Select
                options={
                  /* work around lack of readonly on Select */
                  options as unknown as Array<{
                    label: string;
                    value: ModelId;
                  }>
                }
                value={selectedModelId}
                onChange={(selectable) => {
                  if (selectable.value != null) {
                    setSelectedModelId(selectable.value);
                    field.onChange(selectable.value);
                  }
                }}
              />
            )}
          />
        </VerticalGroup>
      </InlineField>

      <InlineNumberAndUnitsField
        control={control}
        error={errors?.parameters?.trainingWindowValue}
        label="Training data range"
        tooltip="Specify how much historical data the ML algorithm is to train on."
        name="parameters.trainingWindowValue"
        defaultValue={90}
        minimum={1}
        maximum={180}
        unitsName="parameters.trainingWindowUnit"
        unitsOptions={[
          {
            label: 'minutes',
            value: TimeUnit.Minutes,
          },
          {
            label: 'hours',
            value: TimeUnit.Hours,
          },
          {
            label: 'days',
            value: TimeUnit.Days,
          },
          {
            label: 'years',
            value: TimeUnit.Years,
          },
        ]}
        unitsDefaultValue={TimeUnit.Days}
      />

      <InlineNumberAndUnitsField
        control={control}
        error={errors?.parameters?.intervalValue}
        label="Training data interval"
        tooltip={intervalTooltip}
        name="parameters.intervalValue"
        defaultValue={5}
        minimum={1}
        maximum={60}
        unitsName="parameters.intervalUnit"
        unitsOptions={[
          {
            label: 'minutes',
            value: TimeUnit.Minutes,
          },
          {
            label: 'hours',
            value: TimeUnit.Hours,
          },
          {
            label: 'days',
            value: TimeUnit.Days,
          },
        ]}
        unitsDefaultValue={TimeUnit.Minutes}
      />

      {selectedModelId == null ? null : (
        <div className={padding}>
          <Details summary="Advanced algorithm settings">
            <ShowHyperparameterForm
              id={selectedModelId}
              register={register}
              control={control}
              errors={errors}
              holidays={holidays}
            />
          </Details>
        </div>
      )}
    </>
  );
}

const padding = css`
  padding: 7px 0;
`;
