import React from 'react';

import { SceneComponentProps, SceneObjectBase, SceneObjectState } from '@grafana/scenes';
import { Tab, TabContent, TabsBar } from '@grafana/ui';

import { ErroredTab } from './ErroredTab';
import { OOMKilledTab } from './OOMKilledTab';
import { TabID, WorkloadCrashes } from './types';

interface DrilldownSceneState extends SceneObjectState {
  workload: WorkloadCrashes;
  activeTab?: TabID;
  errorTabScene: ErroredTab;
  oomKilledTabScene: OOMKilledTab;
}

export class DrilldownScene extends SceneObjectBase<DrilldownSceneState> {
  public static Component = DrilldownSceneRenderer;
  constructor(state: DrilldownSceneState) {
    super({ ...state, activeTab: state.activeTab ?? (state.workload.errored.length > 0 ? 'errored' : 'oomkilled') });
  }
}

function DrilldownSceneRenderer({ model }: SceneComponentProps<DrilldownScene>) {
  const { workload, activeTab, errorTabScene, oomKilledTabScene } = model.useState();
  const { errored, oomkilled } = workload;
  return (
    <div>
      <TabsBar>
        {errored.length !== 0 && (
          <Tab key="errored" label={`Errored (${errored.length})`} active={activeTab === 'errored'} />
        )}
        {oomkilled.length !== 0 && (
          <Tab key="oomkilled" label={`OOMKilled (${oomkilled.length})`} active={activeTab === 'oomkilled'} />
        )}
      </TabsBar>
      <TabContent>
        {activeTab === 'errored' && <errorTabScene.Component model={errorTabScene} />}
        {activeTab === 'oomkilled' && <oomKilledTabScene.Component model={oomKilledTabScene} />}
      </TabContent>
    </div>
  );
}
