import React, { FC } from 'react';

import { DataSourceInstanceSettings, urlUtil } from '@grafana/data';
import { locationService } from '@grafana/runtime';
import { IconButton, LinkButton, Menu } from '@grafana/ui';

import { css } from '@emotion/css';

import { Outlier } from 'api/types';
import { PLUGIN_ROOT } from 'consts';
import { useMlDataSource } from 'hooks/useSupportedDatasources';
import { AlertStateCounts, RuleState } from 'types';

// duplicated as the forecast button required various subtle changes to work w/outliers
// consolidate into one component if this is needed again elsewhere

/// Create a URL to the 'View alerts' screen with some defaults for the filters.
const generateJobIdFilter = (outlier: Outlier): string => {
  return `ml_job_name="${outlier.name}",ml_job_metric="${outlier.metric}",ml_algorithm="${outlier.algorithm.name}"`;
};

function listAlertsUrl(outlier: Outlier, mlDatasource: DataSourceInstanceSettings, returnTo: string): string {
  const jobIdFilter = generateJobIdFilter(outlier);
  returnTo = returnTo.startsWith('/') ? returnTo : `/${returnTo}`;
  return `/alerting/list?dataSource=${encodeURIComponent(mlDatasource.name)}&queryString=${encodeURIComponent(
    jobIdFilter
  )}&returnTo=${encodeURIComponent(PLUGIN_ROOT + returnTo)}`;
}

export type OutlierAlertButtonType = 'icon' | 'text' | 'menu-item';

interface OutlierAlertIconButtonProps {
  outlier: Outlier;
  alertingEnabled: boolean;
  returnTo: string;
  alertStateCounts: AlertStateCounts;
  kind: OutlierAlertButtonType;
}

export const OutlierAlertIconButton: FC<OutlierAlertIconButtonProps> = ({
  outlier,
  alertingEnabled,
  returnTo,
  kind,
  alertStateCounts,
}) => {
  const mlDataSource = useMlDataSource();
  const hasAlerts = alertStateCounts.total > 0;

  // Don't let users view alerts unless:
  // - Grafana Alerting is enabled
  // - the datasource exists
  // - the outlier has at least one alert created.
  const alertButtonEnabled = alertingEnabled && mlDataSource !== undefined && hasAlerts;

  // Indicate how many alert rules are in each state in the tooltip.
  const tooltip: string = (['total', 'firing', 'error', 'pending', 'inactive'] as Array<RuleState | 'error' | 'total'>)
    // Don't add empty counts to the tooltip.
    .filter((state) => alertStateCounts[state] > 0)
    .map((state) => {
      const count: number = alertStateCounts[state];
      // Grafana Alerting uses 'normal' instead of 'inactive'; be consistent here.
      return `${count} ${state === 'inactive' ? 'normal' : state}`;
    })
    .join(', ');

  const title = !alertingEnabled
    ? 'Grafana Alerting must be enabled to view outlier alerts.'
    : mlDataSource == null
    ? 'Grafana ML datasource not found, please refresh the plugin.'
    : !hasAlerts
    ? 'No alerts are linked to this outlier detector.'
    : // Success case.
      `View alerts using this outlier detector:\n\n${tooltip}`;

  const href = mlDataSource == null ? undefined : listAlertsUrl(outlier, mlDataSource, returnTo);
  const firingOrError = alertStateCounts['firing'] > 0 || alertStateCounts['error'] > 0;
  const className = alertButtonEnabled ? styles.empty : styles.disabledWithTooltip;

  if (kind === 'menu-item' && href !== undefined) {
    return (
      <Menu.Item
        label="View alerts"
        icon="link"
        disabled={!alertButtonEnabled}
        onClick={() => {
          locationService.push(href);
        }}
      />
    );
  }

  return kind === 'icon' ? (
    <IconButton
      key="list-alerts"
      // We use 'link' to show linked alerts, use a 'bell' for the create alert action
      name="link"
      disabled={!alertButtonEnabled}
      className={className}
      tooltip={title}
      // Tooltips only work on enabled buttons, so use a title if it's disabled.
      title={alertButtonEnabled ? undefined : title}
      // There's no href prop on an IconButton, and wrapping it in an anchor messes with the layout,
      // so redirect using JS instead.
      onClick={() =>
        locationService.push({
          pathname: '/alerting/list',
          search: urlUtil.toUrlParams({
            dataSource: mlDataSource?.name ?? null,
            queryString: generateJobIdFilter(outlier),
            returnTo,
          }),
        })
      }
      // We're slightly misusing this API: it's not really a destructive action, but this makes the
      // icon appear red if there's an alert firing, which is useful.
      variant={firingOrError ? 'destructive' : 'secondary'}
    />
  ) : (
    <LinkButton
      key="list-alerts"
      href={href}
      disabled={!alertButtonEnabled}
      className={className}
      title={title}
      variant="secondary"
    >
      View Alerts
    </LinkButton>
  );
};

const styles = {
  empty: css``,
  disabledWithTooltip: css`
    opacity: 0.6;
    cursor: not-allowed;
    pointer-events: auto;
  `,
};
