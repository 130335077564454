import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { PluginPage } from '@grafana/runtime';
import { useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

import Card from 'components/Card';

import Features from './Features';
import GettingStartedCard from './GettingStartedCard';
import MlHowItWorks from './MlHowItWorks';

export default function Home(): JSX.Element | null {
  const styles = useStyles2(getStyles);

  return (
    <PluginPage subTitle="Train ML models to forecast time series metrics into the future">
      <div className={styles.viewContainer}>
        <Card variant="gradient" className={styles.gradientBox}>
          <div className={styles.introText}>What you can do</div>
          <a className={styles.blueLink} href="https://grafana.com/docs/grafana-cloud/machine-learning">
            Read more in the Machine Learning Docs ❯
          </a>
          <Features />
        </Card>
        <div className={styles.infoLayout}>
          <div>
            <Card className={styles.infoCard}>
              <MlHowItWorks />
            </Card>
            <GettingStartedCard className={styles.infoCard} />
          </div>
          <Card className={styles.infoCard}>
            <img src="/public/plugins/grafana-ml-app/assets/screenshot.png" width="100%" />
          </Card>
        </div>
      </div>
    </PluginPage>
  );
}

const getStyles = (theme: GrafanaTheme2) => {
  return {
    viewContainer: css``,
    newFeature: css`
      margin: 0px 10px;
      padding: 10px;
      background-color: green;
      border: solid 1px red;
    `,
    introText: css`
      color: ${theme.colors.text.maxContrast};
      font-size: 24px;
    `,
    blueLink: css`
      color: ${theme.colors.text.link};
    `,
    infoCard: css`
      padding: 18px;
      margin-right: 16px;
      margin-bottom: 16px;
    `,
    infoLayout: css`
      display: flex;
      padding: 16px;
    `,
    gradientBox: css`
      z-index: ${theme.zIndex.navbarFixed};
      top: 0;
      bottom: 0;
      right: 0;
      left: 60px;
      display: flex;
      flex-direction: column;
      margin: 10px;
      padding: 20px;
    `,
  };
};
