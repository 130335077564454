import { arrayToDataFrame, DataFrame, TimeRange } from '@grafana/data';

import { getKubernetesMonitoringUrl, isWorkloadKind } from 'utils/utils.url';

import { WorkloadCrashes } from './types';

export function getPodsDataFrame(pods: Array<Record<string, string>>): DataFrame {
  const columnHeaders = { pod: 'Pod', workloadKind: 'Kind' };
  return arrayToDataFrame(
    pods.map((x) => Object.fromEntries(Object.entries(columnHeaders).map(([key, name]) => [name, x[key]])))
  );
}

export function workloadKubernetesMonitoringURL(
  workload: WorkloadCrashes,
  timeRange: TimeRange,
  promUid: string
): string | undefined {
  const { name, cluster, namespace, errored, oomkilled } = workload;
  const first = errored.length > 0 ? errored[0] : oomkilled[0];
  if (first === undefined) {
    return undefined;
  }
  const workloadKind = first.labels.workloadKind;
  return isWorkloadKind(workloadKind) && first.labels.workload !== undefined
    ? getKubernetesMonitoringUrl({
        cluster,
        namespace,
        workload: name,
        workloadKind,
        from: timeRange.from,
        to: timeRange.to,
        prometheusDatasourceUid: promUid,
      })
    : undefined;
}
