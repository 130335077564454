import React, { useEffect, useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { locationService, PluginPage } from '@grafana/runtime';
import { Alert, Checkbox, LinkButton, Spinner, Stack, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';
import { NumberParam, useQueryParam } from 'use-query-params';

import { useInvestigations } from 'api';
import Card from 'components/Card';
import { Paging } from 'components/Paging';
import { Filter, SearchBar } from 'components/SearchBar';
import { ToolbarTimeRange } from 'components/ToolbarTimeRange';
import { PLUGIN_ROOT } from 'consts';
import { useIsEditor, useTimeRange } from 'hooks';
import { Investigation } from 'types';
import { defaultCreateJobTimeRange, onHandler } from 'utils';

import { RunSiftModal } from '../../RunSiftModal';
import { InvestigationListItem, PlaceholderInvestigationListItem } from './InvestigationListItem';

interface InvestigationListProps {
  showModal?: true;
}

function InvestigationList({ showModal }: InvestigationListProps): JSX.Element | null {
  const isEditor = useIsEditor();
  const styles = useStyles2(getStyles);
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [pageSize, setPageSize] = useQueryParam('pageSize', NumberParam);

  const { onChangeTimeRange, onZoomTimeRange, onChangeTimeZone, timeRange, timeZone } = useTimeRange(
    defaultCreateJobTimeRange()
  );
  const { isError, data, isFetching, isLoading, refetch } = useInvestigations(timeRange);

  const [searchFilters, setSearchFilters] = useState<Filter[]>([]);
  const [onlyShowInteresting, setOnlyShowInteresting] = useState<boolean>(false);

  useEffect(() => {
    void refetch();
  }, [refetch, timeRange, timeZone]);

  if (isError) {
    return (
      <PluginPage>
        <Alert title="Failed loading">Could not load investigations</Alert>
      </PluginPage>
    );
  }

  if (isLoading) {
    return (
      <PluginPage>
        <Spinner />
      </PluginPage>
    );
  }

  const closeModal = () => {
    const returnTo =
      (locationService.getLocation().state as any | undefined)?.returnTo ?? `${PLUGIN_ROOT}/investigations`;
    return locationService.push(returnTo);
  };

  // currently only filters by string match
  // todo: implement tag/label/date/status filtering
  const filter = (_dataToFilter: Investigation[]) => {
    if (searchFilters.length === 0) {
      return data;
    }

    return data?.filter((item) => {
      return searchFilters.every((filter) => {
        if (filter.type === 'match') {
          return item.name.toLowerCase().includes(filter.value.toLowerCase());
        }

        return true;
      });
    });
  };

  const filteredData = data !== undefined ? filter(data) : data;

  const totalItems = filteredData?.length ?? 0;
  const currentPage = page ?? 1;
  const itemsPerPage = pageSize ?? 10;
  const pagedData = filteredData?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage) ?? [];

  return (
    <>
      <main className={styles.viewContainer}>
        <div className={styles.headerBar}>
          {isEditor ? (
            <div className={styles.controls}>
              <SearchBar
                filters={[]}
                onFilter={(filters) => {
                  setSearchFilters(filters);
                }}
              />
              <Stack justifyContent="flex-end">
                <Checkbox
                  label="Interesting?"
                  value={onlyShowInteresting}
                  onChange={(e) => setOnlyShowInteresting(e.currentTarget.checked)}
                />
                <ToolbarTimeRange
                  timeRange={timeRange}
                  isRefreshing={isFetching || isLoading}
                  timeZone={timeZone}
                  onRefresh={onHandler(refetch)}
                  onChangeTimeRange={onChangeTimeRange}
                  onChangeTimeZone={onChangeTimeZone}
                  onZoomTimeRange={onZoomTimeRange}
                />
                <LinkButton
                  className={styles.buttonDrop}
                  size="md"
                  icon="plus"
                  variant="primary"
                  onClick={() =>
                    locationService.push({
                      pathname: `${PLUGIN_ROOT}/investigations/create`,
                      state: { returnTo: locationService.getLocation() },
                    })
                  }
                >
                  New investigation
                </LinkButton>
              </Stack>
            </div>
          ) : (
            <></>
          )}
        </div>

        {isLoading ? (
          <PlaceholderInvestigationListItem />
        ) : filteredData == null || filteredData?.length === 0 ? (
          <Card height="elevated">
            <div className={styles.noForecastBox}>
              There are no investigations found in this time range! To create a new investigation, click the{' '}
              <strong>+ New investigation</strong> button in the upper right corner.
            </div>
          </Card>
        ) : (
          pagedData.map((item) => (
            <div key={`investigationlistitem${item.id}`} className={styles.jobItem}>
              <InvestigationListItem onlyShowInteresting={onlyShowInteresting} key={item.id} item={item} />
            </div>
          ))
        )}
        {totalItems > 0 ? (
          <Paging
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalItems={totalItems}
            onItemsPerPageChange={(newPageSize) => setPageSize(newPageSize)}
            onPageChange={(newPage) => setPage(newPage)}
          />
        ) : null}
      </main>
      <RunSiftModal show={showModal ?? false} onClose={closeModal} />
    </>
  );
}

const getStyles = (theme: GrafanaTheme2) => {
  return {
    viewContainer: css``,
    gradientBox: css`
      z-index: ${theme.zIndex.navbarFixed};
      top: 0;
      bottom: 0;
      right: 0;
      left: 60px;
      display: flex;
      flex-direction: column;
      margin: 20px;
    `,
    infoCard: css`
      padding: 18px;
      margin-right: 16px;
      margin-bottom: 16px;
    `,
    infoLayout: css`
      display: flex;
      padding: 16px;
    `,
    bgLoadingSpinner: css`
      width: 32px;
      height: 32px;
      transition: 0.15s opacity ease-in-out;
      margin-top: 8px;
    `,
    buttonDrop: css`
      filter: drop-shadow(rgba(19, 2, 43, 0.15) 2px 4px 6px);
      margin: 0px 0px 0px 8px;
      width: 180px;
    `,
    controls: css`
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 0px 20px 0px;
      gap: 4px;
    `,
    headerBar: css`
      width: 100%;
      display: flex;
      margin-bottom: ${theme.spacing(1)};
    `,
    noForecastBox: css`
      padding: 18px;
      border: ;
    `,
    jobItem: css`
      margin-top: ${theme.spacing(1)};
    `,
    feedbackText: css`
      font-size: 14px;
      color: ${theme.colors.text.secondary};
      width: 100%;
      align-self: center;
      display: flex;
      justify-content: space-between;
    `,
    pageHeader: css`
      display: flex;
      align-items: center;
      gap: 20px;
      height: 32px;
    `,
    pageTitle: css`
      font-family: Inter, Helvetica, Arial, sans-serif;
      font-size: 28px;
      font-weight: 500;
      line-height: 1.2;
      color: ${theme.colors.text.primary};
    `,
    pagingContainer: css`
      display: flex;
      justify-content: flex-end;
      margin-top: ${theme.spacing(2)};
    `,
  };
};

export { InvestigationList };
