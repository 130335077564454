import React from 'react';

import { getDefaultTimeRange, LoadingState, TimeRange } from '@grafana/data';
import {
  PanelBuilders,
  SceneComponentProps,
  SceneDataNode,
  SceneFlexItem,
  SceneFlexLayout,
  SceneObjectBase,
  SceneObjectState,
  SceneQueryRunner,
} from '@grafana/scenes';
import { LinkButton } from '@grafana/ui';

import { applyTableFieldOverrides } from 'utils/utils.overrides';
import { getKubernetesMonitoringUrl, getLokiExploreUrl, KubernetesMonitoringPodOptions } from 'utils/utils.url';

import { WorkloadCrashes } from './types';
import { getPodsDataFrame } from './utils';

interface ErroredTabState extends SceneObjectState {
  workload: WorkloadCrashes;
  timeRange: TimeRange;
  lokiUid: string;
  promUid: string;
  scene?: SceneFlexLayout;
}

export class ErroredTab extends SceneObjectBase<ErroredTabState> {
  public static Component = ErroredTabRenderer;
  constructor(state: Omit<ErroredTabState, 'scene'>) {
    super({ ...state, scene: createErrorTabScene(state) });
  }
}

function selectors(cluster: string, namespace: string, pods: string[]): string {
  const podsJoined = pods.join('|');
  return `{cluster="${cluster}", namespace="${namespace}", pod=~"${podsJoined}"}`;
}

function query(cluster: string, namespace: string, pods: string[]): string {
  return `${selectors(cluster, namespace, pods)} |~\`(?i)(panic:|traceback |error:|fatal)\` !~\`(?i)(info|debug)\``;
}

function rateQuery(cluster: string, namespace: string, pods: string[]): string {
  return `sum by (pod) (rate(${query(cluster, namespace, pods)}[5m]))`;
}

function createErrorTabScene({
  workload,
  timeRange,
  lokiUid,
  promUid,
}: Omit<ErroredTabState, 'scene'>): SceneFlexLayout | undefined {
  if (workload.errored.length === 0) {
    return undefined;
  }
  const { from, to } = timeRange;
  const { cluster, namespace } = workload;
  const pods = workload.errored.map((x) => x.labels.pod);
  const logRateQuery = {
    refId: 'RATE',
    datasource: {
      uid: lokiUid,
      type: 'loki',
    },
    expr: rateQuery(cluster, namespace, pods),
    legendFormat: '{{ pod }}',
  };
  const logsQuery = {
    refId: 'LOGS',
    datasource: {
      uid: lokiUid,
      type: 'loki',
    },
    expr: query(cluster, namespace, pods),
  };
  const podsDF = getPodsDataFrame(workload.errored.map((x) => x.labels));
  const podOptions: KubernetesMonitoringPodOptions = {
    from,
    to,
    prometheusDatasourceUid: promUid,
    lokiDatasourceUid: lokiUid,
    cluster,
    namespace,
    pod: '${__value.text}',
    // These will be populated by the applyTableFieldOverrides function.
    workload: workload.name,
    // @ts-ignore-next-line
    workloadKind: '${__data.fields["Kind"].text}',
  };
  podsDF.fields[0].config.links = [
    {
      title: 'View pod in Kubernetes Monitoring',
      url: getKubernetesMonitoringUrl(podOptions),
    },
  ];
  const tableData = {
    series: [applyTableFieldOverrides(podsDF)],
    state: LoadingState.Done,
    timeRange: getDefaultTimeRange(),
  };
  return new SceneFlexLayout({
    children: [
      // Error rate panel
      new SceneFlexItem({
        body: PanelBuilders.timeseries()
          .setData(new SceneQueryRunner({ queries: [logRateQuery] }))
          .setTitle('Error log rate')
          .build(),
        height: '200px',
      }),
      // Error logs panel
      new SceneFlexItem({
        body: PanelBuilders.logs()
          .setData(new SceneQueryRunner({ queries: [logsQuery] }))
          .setTitle('Log examples')
          .setOption('showCommonLabels', true)
          .setOption('showLogContextToggle', true)
          .setOption('enableLogDetails', true)
          .setHeaderActions(
            <LinkButton
              key="explore"
              href={getLokiExploreUrl(lokiUid, query(cluster, namespace, pods), timeRange)}
              icon="compass"
              tooltip="View in Explore"
              size="sm"
              variant="secondary"
              target="_blank"
            />
          )
          .setOption('showCommonLabels', true)
          .build(),
        height: '300px',
      }),
      new SceneFlexItem({
        body: PanelBuilders.table()
          .setData(new SceneDataNode({ data: tableData }))
          .build(),
        height: '300px',
      }),
    ],
    direction: 'column',
    height: '100%',
    width: '100%',
  });
}

function ErroredTabRenderer({ model }: SceneComponentProps<ErroredTab>) {
  const { scene } = model.useState();
  if (scene === undefined) {
    return null;
  }
  return <scene.Component model={scene} />;
}
