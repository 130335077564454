import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Icon, IconName, Tooltip, useStyles2 } from '@grafana/ui';

import { css, cx } from '@emotion/css';

export type BadgeType =
  | 'preview'
  | 'interesting'
  | 'error'
  | 'enabled'
  | 'disabled'
  | 'running'
  | 'skipped'
  | 'warning'
  | 'pending'
  | 'ready';
interface BadgeProps {
  badgeType: BadgeType;
  tooltipText: string;
}

export const Badge = ({ badgeType, tooltipText }: BadgeProps) => {
  const styles = useStyles2(getStyles);

  const config: Record<string, { icon: IconName | 'spinner'; text: string; style: string }> = {
    running: {
      icon: 'spinner',
      text: 'In progress...',
      style: styles.running,
    },
    preview: {
      icon: 'rocket',
      text: 'Public preview',
      style: styles.preview,
    },
    interesting: {
      icon: 'bolt',
      text: 'Interesting result',
      style: styles.interesting,
    },
    error: {
      icon: 'exclamation-triangle',
      text: 'Failed to run',
      style: styles.error,
    },
    enabled: {
      icon: 'play',
      text: 'Enabled',
      style: styles.enabled,
    },
    disabled: {
      icon: 'pause',
      text: 'Disabled',
      style: styles.disabled,
    },
    skipped: {
      icon: 'forward',
      text: 'Skipped',
      style: styles.skipped,
    },
    warning: {
      icon: 'exclamation-circle',
      text: 'Warning',
      style: styles.warning,
    },
    pending: {
      icon: 'spinner',
      text: 'Pending',
      style: styles.pending,
    },
    ready: {
      icon: 'check',
      text: 'Ready',
      style: styles.ready,
    },
  };

  const selected = config[badgeType];

  // no tooltip if no text
  if (tooltipText === '') {
    return (
      <div className={cx(styles.container, selected.style)}>
        <Icon name={selected.icon as IconName} />
        <div>{selected.text}</div>
      </div>
    );
  }

  return (
    <Tooltip content={tooltipText}>
      <div className={cx(styles.container, selected.style)}>
        <Icon name={selected.icon as IconName} />
        {selected.text}
      </div>
    </Tooltip>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  container: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 3px;
    height: 28px;
    min-width: 150px;
    padding: 5px 10px 5px 5px;
    line-height: 28px;
    cursor: default;
    font-size: 14px;
  `,
  preview: css`
    background-color: ${theme.isDark ? 'rgba(30, 44, 69, 255)' : 'rgb(170, 200, 255)'};
    color: ${theme.isDark ? 'rgb(110, 159, 255)' : 'rgb(0, 68, 194)'};
    border: solid 1px ${theme.isDark ? 'rgba(35, 58, 99, 255)' : 'rgb(135, 170, 255)'};
  `,
  interesting: css`
    background-color: ${theme.colors.background.secondary};
    color: #ff8833;
    border: solid 1px ${theme.colors.border.weak};
  `,
  error: css`
    background-color: rgb(196, 22, 42);
    color: rgb(255, 166, 176);
    border: solid 1px rgb(255, 166, 176);
  `,
  enabled: css`
    background-color: ${theme.colors.background.secondary};
    color: rgb(57, 163, 87);
    border: solid 1px ${theme.colors.border.weak};
    min-width: 92px;
    height: 24px;
    cursor: pointer;
  `,
  disabled: css`
    background-color: ${theme.colors.background.secondary};
    color: rgb(214, 147, 47);
    border: solid 1px ${theme.colors.border.weak};
    min-width: 92px;
    height: 24px;
    cursor: pointer;
  `,
  running: css`
    background-color: rgba(61, 113, 217, 0.15);
    color: ${theme.colors.secondary.text};
    border: solid 1px rgba(110, 159, 255, 0.25);
    min-width: 132px;
  `,
  skipped: css`
    background-color: ${theme.colors.secondary.main};
    color: ${theme.colors.secondary.text};
    border: solid 1px ${theme.colors.border.medium};
    min-width: 92px;
  `,
  warning: css`
    background-color: ${theme.colors.secondary.main};
    color: orange;
    border: solid 1px ${theme.colors.border.medium};
    min-width: 97px;
    height: 24px;
  `,
  pending: css`
    background-color: ${theme.colors.secondary.main};
    color: ${theme.isDark ? 'rgb(110, 159, 255)' : 'rgb(0, 68, 194)'};
    border: solid 1px ${theme.isDark ? 'rgba(35, 58, 99, 255)' : 'rgb(135, 170, 255)'};
    min-width: 97px;
  `,
  ready: css`
    background-color: ${theme.colors.secondary.main};
    color: rgb(57, 163, 87);
    border: solid 1px ${theme.colors.border.weak};
    min-width: 82px;
    height: 24px;
  `,
});
