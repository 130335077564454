import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { GrafanaTheme2 } from '@grafana/data';
import { locationService, PluginPage } from '@grafana/runtime';
import { Button, LinkButton, Spinner, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

import { useInvestigation } from 'api';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { QueryRunnerProvider } from 'components/QueryRunner';
import { PLUGIN_ROOT } from 'consts';
import useRudderStack, { RudderstackEvents } from 'hooks/useRudderstack';

import { RerunInvestigationButton } from './RerunInvestigationButton';
import { shouldShowTimelineButton, ViewInvestigationContent } from './ViewInvestigationContent';

export const ViewInvestigation: React.FC = () => {
  const styles = useStyles2(getStyles);
  const [showTimeline, setShowTimeline] = useState(true);
  const { id } = useParams<{ id: string }>();
  const { data, refetch, isFetching, isLoading, isError } = useInvestigation(id!);
  const trackRudderstackEvent = useRudderStack();

  useEffect((): void => {
    if (isLoading || data == null) {
      return;
    }
    trackRudderstackEvent(RudderstackEvents.ViewInvestigation, {
      analysesMetadata: data.analyses.countsByStage,
      status: data.status,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, trackRudderstackEvent]);

  const defaultTitle = 'View investigation';

  const returnToList = () => {
    const returnTo =
      (locationService.getLocation().state as any | undefined)?.returnTo ?? `${PLUGIN_ROOT}/investigations`;
    return locationService.push(returnTo);
  };

  const timelineButtonContent = showTimeline ? 'Hide timeline' : 'Show timeline';
  return (
    <PluginPage
      pageNav={{ text: data?.name ?? defaultTitle }}
      subTitle=""
      actions={
        <>
          {shouldShowTimelineButton(data?.inputs) && (
            <Button
              variant="secondary"
              flex-direction="right"
              onClick={() => {
                setShowTimeline(!showTimeline);
              }}
            >
              {timelineButtonContent}
            </Button>
          )}
          <RerunInvestigationButton investigation={data} />
        </>
      }
      renderTitle={() => {
        return (
          <div className={styles.pageHeader}>
            <LinkButton variant="secondary" icon="angle-left" onClick={returnToList} />
            <div className={styles.pageTitle}>{data?.name ?? 'Sift investigation'}</div>
            {isFetching || isLoading ? <Spinner className={styles.bgLoadingSpinner} /> : <></>}
          </div>
        );
      }}
    >
      {isError ? (
        <div>Error</div>
      ) : id == null || isLoading || data == null ? (
        <LoadingIndicator />
      ) : (
        <QueryRunnerProvider>
          <ViewInvestigationContent investigation={data} refetchInvestigation={refetch} showTimeline={showTimeline} />
        </QueryRunnerProvider>
      )}
    </PluginPage>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    bgLoadingSpinner: css`
      width: 32px;
      height: 32px;
      transition: 0.15s opacity ease-in-out;
      margin-top: 8px;
    `,
    pageHeader: css`
      display: flex;
      align-items: center;
      gap: 20px;
      height: 32px;
      width: 100%;
    `,
    pageTitle: css`
      font-family: Inter, Helvetica, Arial, sans-serif;
      font-size: 24px;
      font-weight: 400;
      line-height: 1.2;
      color: ${theme.colors.text.primary};
    `,
    viewListButton: css`
      margin-left: auto;
    `,
  };
};
