import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Button, Icon, Input, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

import { SearchSuffix } from './SearchSuffix';

export interface Filter {
  type: string;
  value: string;
}

interface SearchBarProps {
  filters: Filter[];
  onFilter: (filters: Filter[]) => void;
  showAddFilter?: boolean;
}

export const SearchBar = ({ onFilter, showAddFilter = false }: SearchBarProps) => {
  const styles = useStyles2(getStyles);
  const searchRef = useRef<HTMLInputElement | null>(null);
  const [search, setSearch] = useState<string>('');

  const parseSearch = (search: string): Filter[] => {
    return [
      {
        type: 'match',
        value: search,
      },
    ];
  };

  const handleKeyPress = useCallback((e: KeyboardEvent) => {
    const { code } = e;
    if (code === 'Slash') {
      searchRef.current?.focus();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keyup', handleKeyPress);
    return () => {
      window.removeEventListener('keyup', handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <div className={styles.container}>
      <Input
        ref={searchRef}
        suffix={<SearchSuffix />}
        prefix={<Icon name="search" />}
        placeholder="Search or add filter"
        value={search}
        onChange={(e) => setSearch(e.currentTarget.value)}
        onBlur={() => onFilter(parseSearch(search))}
        onKeyUp={() => onFilter(parseSearch(search))}
      />
      {showAddFilter ? (
        <Button icon="filter" variant="secondary">
          Add filter
        </Button>
      ) : null}
    </div>
  );
};

const getStyles = () => ({
  container: css`
    width: 100%;
    display: flex;
    gap: 12px;
    font-size: 9px;
  `,
});
