import React from 'react';

import { TimeRange } from '@grafana/data';
import { SceneComponentProps, SceneObjectBase, SceneObjectState } from '@grafana/scenes';
import { Card, IconButton, TagList, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

import { TabID, WorkloadCrashes } from './types';
import { workloadKubernetesMonitoringURL } from './utils';

interface WorkloadCardState extends SceneObjectState {
  workload: WorkloadCrashes;
  timeRange: TimeRange;
  promUid: string;
  lokiUid: string;
  onDrilldown: (tag?: TabID) => void;
}

export class WorkloadCard extends SceneObjectBase<WorkloadCardState> {
  public static Component = WorkloadCardRenderer;
  constructor(props: WorkloadCardState) {
    super(props);
  }
}

function WorkloadCardRenderer({ model }: SceneComponentProps<WorkloadCard>) {
  const { workload, timeRange, promUid, onDrilldown } = model.useState();
  const styles = useStyles2(getStyles);
  const { name, errored, oomkilled } = workload;

  if (errored.length === 0 && oomkilled.length === 0) {
    return null;
  }

  const errorsTags = errored.length > 0 ? [`Errored (${errored.length})`] : [];
  const oomKillTags = oomkilled.length > 0 ? [`OOMKilled (${oomkilled.length})`] : [];

  const url = workloadKubernetesMonitoringURL(workload, timeRange, promUid);

  return (
    <>
      <Card onClick={() => onDrilldown()} className={styles.card}>
        <Card.Heading>{name}</Card.Heading>
        <Card.Tags>
          <TagList
            tags={[...errorsTags, ...oomKillTags]}
            onClick={(tag) => onDrilldown(tag.includes('Errored') ? 'errored' : 'oomkilled')}
            className={styles.tags}
            getColorIndex={(tag) => (tag.includes('Errored') ? 0 : 1)}
          />
        </Card.Tags>
        <Card.SecondaryActions>
          {url && (
            <IconButton
              name="kubernetes"
              size="md"
              // @ts-expect-error
              target="_blank"
              href={url}
              tooltip="View in Kubernetes Monitoring"
            />
          )}
        </Card.SecondaryActions>
      </Card>
    </>
  );
}

const getStyles = () => ({
  card: css`
    padding: 12px;
    grid-template-colums: 1fr;
    grid-template-rows: 1fr 2fr 1fr;
    grid-template-areas:
      'Tags'
      'Heading'
      'SecondaryActions';
    grid-auto-columns: auto;
  `,
  tags: css`
    justify-content: flex-start;
  `,
});
