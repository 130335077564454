import React, { PropsWithChildren, useState } from 'react';

import { Button, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';
import { ProviderWrapper } from 'App';

import { RunSiftModal, SiftRunModalProps } from 'projects/Sift/RunSiftModal';
import { NewInvestigation } from 'types';

// This wrapper allows us to display a button for incident
// that when clicked, will open the RunSiftModal - which
// allows a user to start a new investigation
export function RunSiftModalWrapper(props: PropsWithChildren<{ context?: SiftRunModalProps }>) {
  const [showModal, setShowModal] = useState(false);
  const styles = useStyles2(getStyles);
  return (
    <ProviderWrapper>
      <div>
        <Button
          className={styles.button}
          type="button"
          variant="primary"
          fill="outline"
          onClick={() => setShowModal(true)}
        >
          Start Sift Investigation
        </Button>

        <RunSiftModal
          show={showModal}
          title="Run investigation"
          onClose={() => setShowModal(false)}
          overrideSubmit={props.context?.overrideSubmit ?? false}
          onSubmit={(form?: NewInvestigation) => {
            if (props.context?.onSubmit !== undefined) {
              props.context?.onSubmit(form);
            }
            setShowModal(false);
          }}
        />
      </div>
    </ProviderWrapper>
  );
}

const getStyles = () => ({
  button: css`
    width: 190px;
    margin: 0 auto;
    text-align: center;
  `,
});
