// import '@fullcalendar/react/dist/vdom';
import '@iroomit/react-date-range/dist/styles.css'; // main css file
import '@iroomit/react-date-range/dist/theme/default.css'; // theme css file

import React, { PropsWithChildren } from 'react';
import { FeatureDescription, Features } from 'react-enable';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { AppRootProps } from '@grafana/data';

import { parse, stringify } from 'query-string';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { IS_DEV } from 'consts';
import { FEATURES } from 'features';
import { QueryContextProvider } from 'hooks/useQueryContext';
import FreeUser from 'projects/Home/FreeUser';
import { MlAppSettings } from 'types';
import { queryClient } from 'utils/queryClient';
import { PluginMetaContext } from 'utils/utils.plugin';

import { AppContent } from './AppContent';
import { TenantProvider, useTenant } from './tenant';

// A provider that uses tenant and feature information to feature flags to downstream
// components so they can access it via the `useFeature` hook.
function FeatureProvider({ children }: PropsWithChildren<{}>): JSX.Element {
  const tenant = useTenant();
  const features = FEATURES.map((feature) => {
    if (tenant?.features?.[feature.name] === true) {
      feature.defaultValue = true;
    }
    // Type cast is required because our features use a union type as the `K`
    // type parameter, but the `Features` component expects a `string`.
    return feature as FeatureDescription<string>;
  });
  return (
    <Features features={features} storage={IS_DEV ? window.localStorage : window.sessionStorage}>
      {children}
    </Features>
  );
}

// loads tenant data and setups up feature flags
function AppWithAccess() {
  const tenant = useTenant();
  if (tenant !== undefined && !tenant.canAccess) {
    return <FreeUser orgInfo={tenant} />;
  }
  return <AppContent tenantData={tenant} />;
}

// we use this wrapper for some components that are exposed as extension points
// this provider wrapper does not include all of the providers needed for our app
// instead it pulls in only the providers needed for the extension points
export function ProviderWrapper({ children }: PropsWithChildren<{}>): JSX.Element {
  return (
    <QueryParamProvider
      adapter={ReactRouter6Adapter}
      options={{
        searchStringToObject: parse,
        objectToSearchString: stringify,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <QueryContextProvider>
          <TenantProvider>
            <FeatureProvider>{children}</FeatureProvider>
          </TenantProvider>
        </QueryContextProvider>
      </QueryClientProvider>
    </QueryParamProvider>
  );
}

export function App({ meta }: AppRootProps<MlAppSettings>): JSX.Element {
  return (
    <QueryParamProvider
      adapter={ReactRouter6Adapter}
      options={{
        searchStringToObject: parse,
        objectToSearchString: stringify,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <PluginMetaContext.Provider value={meta}>
          <QueryContextProvider>
            <TenantProvider>
              <FeatureProvider>
                <AppWithAccess />
              </FeatureProvider>
            </TenantProvider>
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          </QueryContextProvider>
        </PluginMetaContext.Provider>
      </QueryClientProvider>
    </QueryParamProvider>
  );
}
