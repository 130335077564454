import React from 'react';

import {
  SceneComponentProps,
  SceneFlexItem,
  SceneFlexLayout,
  SceneLayout,
  SceneLayoutState,
  SceneObjectBase,
} from '@grafana/scenes';

interface SceneFlexLayoutWithTitleState extends SceneLayoutState {
  title: string;
  flexLayout: SceneFlexLayout;
  children: SceneFlexItem[];
}

export class SceneFlexLayoutWithTitle extends SceneObjectBase<SceneFlexLayoutWithTitleState> implements SceneLayout {
  public static Component = SceneFlexLayoutWithTitleRenderer;
  constructor(state: SceneFlexLayoutWithTitleState) {
    super({ ...state, children: [], flexLayout: new SceneFlexLayout({ ...state.flexLayout.state, children: [] }) });
    this.subscribeToState((x) => x.flexLayout.setState({ children: x.children.map((x) => x.clone()) }));
  }
  isDraggable(): boolean {
    return this.state.flexLayout.isDraggable();
  }
}

function SceneFlexLayoutWithTitleRenderer({ model }: SceneComponentProps<SceneFlexLayoutWithTitle>) {
  const { title, flexLayout } = model.useState();
  return (
    <div>
      <h4>{title}</h4>
      <flexLayout.Component model={flexLayout} />
    </div>
  );
}
