import React from 'react';

import { FieldType, TimeRange } from '@grafana/data';
import {
  behaviors,
  EmbeddedScene,
  PanelBuilders,
  SceneControlsSpacer,
  SceneDataLayerSet,
  SceneFlexItem,
  SceneFlexLayout,
  SceneGridItem,
  SceneGridLayout,
  SceneQueryRunner,
  SceneTimePicker,
  SceneTimeRange,
  SceneTimeRangeCompare,
  VizPanel,
} from '@grafana/scenes';
import { DashboardCursorSync, TooltipDisplayMode } from '@grafana/schema';
import { Icon, IconButton, Stack } from '@grafana/ui';

import { CustomAnnotationsDataLayer } from 'components/CustomAnnotations';
import { ExploreButton } from 'components/ExploreButton';
import { SceneDrilldown } from 'components/SceneDrillDown/SceneDrillDown';
import { useScene } from 'hooks';
import { SiftModalData } from 'types';
import { toTimeRange } from 'utils';
import { findLabelMatcher } from 'utils/utils.sift';

interface ErrorSeries {
  name: string;
  timestamp: string;
  interestingLabels?: string[];
  httpErrorLabelName?: string;
  // LabelSet is deprecated. It is kept here for backward compatibility.
  labelSet?: Array<Record<string, string>>;
}

interface HTTPErrorSeriesDetails {
  windowStart?: string;
  // httpErrorCodeLabels is deprecated. It is kept here for backward compatibility.
  httpErrorCodeLabels?: string[];
  httpErrorCodeRegex: string;
  series: ErrorSeries[];
}

export function HTTPErrorSeries({ analysis, investigation, datasources }: SiftModalData): React.ReactElement | null {
  const datasourceUid = datasources.prometheusDatasource.uid;
  // Memoise without dependencies since it won't change.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const timeRange = new SceneTimeRange({ value: investigation.timeRange });
  const investigationTimeRange = investigation.timeRange;
  const cluster = findLabelMatcher(investigation, 'cluster', 'prometheusDatasource')?.value ?? '';
  const namespace = findLabelMatcher(investigation, 'namespace', 'prometheusDatasource')?.value ?? '';

  const details = (analysis.result?.details ?? {
    series: [],
    httpErrorCodeLabels: [],
  }) as unknown as HTTPErrorSeriesDetails;

  if (!analysis.result.interesting || details.series.length === 0) {
    return null;
  }
  return (
    <>
      <HTTPErrorSeriesInner
        analysisId={analysis.id}
        datasourceUid={datasourceUid}
        details={details}
        timeRange={timeRange}
        investigationTimeRange={investigationTimeRange}
        cluster={cluster}
        namespace={namespace}
      />
    </>
  );
}

interface HTTPErrorSeriesInnerProps {
  analysisId: string;
  datasourceUid: string;
  timeRange: SceneTimeRange;
  investigationTimeRange: TimeRange;
  cluster: string;
  namespace: string;
  details: HTTPErrorSeriesDetails;
}

function getOuterRow(
  name: string,
  idx: number,
  cluster: string,
  namespace: string,
  datasourceUid: string,
  timestamp: string,
  timeRange: SceneTimeRange,
  investigationTimeRange: TimeRange,
  setDrilldownIdx: (idx: number) => void
): SceneFlexItem {
  const increasedErrorsTimestamp = new Date(timestamp ?? '').getTime();
  const annotationsLayers = [
    new CustomAnnotationsDataLayer({
      name: 'Custom annotations populated from analysis events',
      dataframe: [
        {
          fields: [
            {
              name: 'time',
              type: FieldType.time,
              config: {},
              values: [increasedErrorsTimestamp],
            },
            {
              name: 'text',
              type: FieldType.string,
              config: {},
              values: ['Increased HTTP errors detected'],
            },
          ],
          length: 1,
        },
      ],
      timerange: toTimeRange({ from: increasedErrorsTimestamp, to: increasedErrorsTimestamp }, 'browser'),
    }),
  ];
  const expr = `sum(rate(${name}{cluster="${cluster}", namespace="${namespace}"}[5m]))`;
  const queryRunner = new SceneQueryRunner({
    datasource: {
      type: 'prometheus',
      uid: datasourceUid,
    },
    queries: [
      {
        refId: name,
        expr: expr,
      },
    ],
    $data: new SceneDataLayerSet({ layers: annotationsLayers }),
  });

  // building the VizPanel here instead of using the PanelBuilders.timeseries() method
  // as with the annotation added to the data layer the PanelBuilders.timeseries() method
  // was failing when hovering over the annotation. This is solved by adding the extendedPanelContext config
  const body = new VizPanel({
    pluginId: 'timeseries',
    title: name,
    options: {
      legend: {
        showLegend: true,
      },
      tooltip: {
        mode: TooltipDisplayMode.Single,
      },
    },
    headerActions: (
      <Stack alignItems="center" gap={1}>
        <IconButton
          name="history-alt"
          aria-label="Reset time range"
          tooltip="Reset time range"
          onClick={() => timeRange.onTimeRangeChange(investigationTimeRange)}
        />
        <ExploreButton
          queries={[expr]}
          datasourceUid={datasourceUid}
          timeRange={toTimeRange({ from: timeRange.state.from, to: timeRange.state.to }, 'browser')}
        />
        <a
          className="external-link"
          onClick={(e) => {
            e.preventDefault();
            setDrilldownIdx(idx);
          }}
        >
          <Icon name="arrow-right" />
          Drill down
        </a>
      </Stack>
    ),
    $data: queryRunner,
    extendPanelContext: (vizpanel, ctx) => {
      ctx.canEditAnnotations = () => {
        return false;
      };
      ctx.canDeleteAnnotations = () => {
        return false;
      };
      return ctx;
    },
  });

  return new SceneFlexItem({
    minHeight: '300px',
    body,
  });
}

function getOuterChildren(
  series: ErrorSeries[],
  cluster: string,
  namespace: string,
  datasourceUid: string,
  timeRange: SceneTimeRange,
  investigationTimeRange: TimeRange,
  setDrilldownIdx: (idx: number) => void
): SceneFlexItem[] {
  return series.map(({ name, timestamp }, idx) =>
    getOuterRow(
      name,
      idx,
      cluster,
      namespace,
      datasourceUid,
      timestamp,
      timeRange,
      investigationTimeRange,
      setDrilldownIdx
    )
  );
}

function getDrilldownItem(
  label: string,
  idx: number,
  seriesName: string,
  statusLabel: string,
  namespace: string,
  cluster: string,
  datasourceUid: string,
  statusRegex: string,
  timeRange: TimeRange
): SceneGridItem {
  const title = `By ${label}`;
  const expr = `sum by (${label}) (rate(${seriesName}{cluster="${cluster}", namespace="${namespace}", ${statusLabel}=~"${statusRegex}"}[5m]))`;
  const queryRunner = new SceneQueryRunner({
    datasource: {
      type: 'prometheus',
      uid: datasourceUid,
    },
    queries: [
      {
        refId: 'A',
        expr: expr,
      },
    ],
  });
  return new SceneGridItem({
    // Position the panels in a grid of squares.
    x: 8 * (idx % 3),
    y: 8 * Math.floor(idx / 3),
    width: 8,
    height: 8,
    body: PanelBuilders.timeseries()
      .setTitle(title)
      .setHeaderActions(<ExploreButton queries={[expr]} datasourceUid={datasourceUid} timeRange={timeRange} />)
      .setData(queryRunner)
      .setDescription(`${seriesName} errors by ${label}`)
      .build(),
  });
}

// Find labels with at least 2 different values, and the status code label.
//
// This is a fallback function for old analyses before we started doing it in the backend.
function getLabelInfo(
  labelSet: ErrorSeries['labelSet'],
  httpCodeRelatedLabels: string[]
): { interestingLabels: string[]; statusLabel: string } {
  // Find all the labels and their unique values.
  const labelValues = (labelSet ?? []).reduce((acc, label) => {
    Object.entries(label).forEach(([key, value]) => acc.set(key, (acc.get(key) ?? new Set([])).add(value)));
    return acc;
  }, new Map<string, Set<string>>());
  // Find labels with at least 2 different values.
  const interestingLabels = Array.from(labelValues.entries())
    .filter(([_, vals]) => vals.size > 1)
    .map(([k, _]) => k)
    .sort();
  // Figure out what the status code label looks like.
  const statusLabel = Array.from(labelValues.keys()).find((label) => httpCodeRelatedLabels.includes(label)) as string;
  return { interestingLabels, statusLabel };
}

function getDrilldownScene(
  series: ErrorSeries,
  cluster: string,
  namespace: string,
  datasourceUid: string,
  httpCodeRelatedLabels: string[] | undefined,
  interestingLabels: string[] | undefined,
  statusLabel: string | undefined,
  statusRegex: string,
  timeRange: TimeRange
): SceneGridItem[] {
  const { name } = series;
  // If we don't have the interesting labels we need to fall back to doing this computation
  // here.
  if (statusLabel === undefined || interestingLabels === undefined) {
    // This should be defined here, but that's not expressed in the types; add
    // a default value to make TypeScript happy.
    httpCodeRelatedLabels = httpCodeRelatedLabels ?? [
      'code',
      'http_status',
      'status_code',
      'statuscode',
      'response_code',
      'status',
    ];
    ({ interestingLabels, statusLabel } = getLabelInfo(series.labelSet, httpCodeRelatedLabels));
  }

  // Put the status code label first.
  const statusLabelIdx = interestingLabels.indexOf(statusLabel);
  const labels = [
    statusLabel,
    ...interestingLabels.slice(0, statusLabelIdx),
    ...(statusLabelIdx === -1 ? [] : interestingLabels.slice(statusLabelIdx + 1)),
  ];

  // Typescript doesn't seem to realize that statusLabel is defined here, but if we
  // assign it to a new const binding it's happy.
  // See https://github.com/microsoft/TypeScript/issues/35124.
  const statusLabel2 = statusLabel;
  return labels.map((label, idx) =>
    getDrilldownItem(label, idx, name, statusLabel2, namespace, cluster, datasourceUid, statusRegex, timeRange)
  );
}

function HTTPErrorSeriesInner({
  analysisId,
  datasourceUid,
  timeRange,
  investigationTimeRange,
  cluster,
  namespace,
  details,
}: HTTPErrorSeriesInnerProps): React.ReactElement {
  const getScene = () => {
    return new EmbeddedScene({
      $behaviors: [new behaviors.CursorSync({ key: 'http-error-series', sync: DashboardCursorSync.Crosshair })],
      $timeRange: timeRange,
      controls: [new SceneControlsSpacer(), new SceneTimePicker({}), new SceneTimeRangeCompare({})],
      body: new SceneDrilldown({
        // Outer view.
        outerLayout: new SceneFlexLayout({
          direction: 'column',
          // This will be computed by the getOuterChildren function.
          children: [],
        }),
        getOuterChildren: (setDrilldownIdx) =>
          getOuterChildren(
            details.series,
            cluster,
            namespace,
            datasourceUid,
            timeRange,
            investigationTimeRange,
            setDrilldownIdx
          ),
        drilldownLayout: new SceneGridLayout({
          isDraggable: true,
          isResizable: true,
          isLazy: true,
          // This will be computed by the getDrilldownChildren function.
          children: [],
        }),
        getDrilldownChildren: (idx) =>
          getDrilldownScene(
            details.series[idx],
            cluster,
            namespace,
            datasourceUid,
            details.httpErrorCodeLabels,
            details.series[idx].interestingLabels,
            details.series[idx].httpErrorLabelName,
            details.httpErrorCodeRegex,
            toTimeRange({ from: timeRange.state.from, to: timeRange.state.to }, 'browser')
          ),
        getDrilldownTitle: (idx) => details.series[idx].name,
      }),
    });
  };
  // Cache the scene using the analysis ID as the key, so that
  // the data isn't refetched if the analysis is closed and re-opened.
  const scene = useScene(getScene, analysisId);
  return <scene.Component model={scene} />;
}
