/* eslint-disable camelcase */

import { Analysis } from 'types';

interface WindowWithRudderStack {
  rudderanalytics?: {
    track: (event: string, params: object) => void;
  };
}

export const trackRudderStackEvent = (event: RudderstackEvents, params: object): void => {
  (window as WindowWithRudderStack).rudderanalytics?.track(event, {
    ...params,
    instance_slug: location.hostname.split('.')[0],
  });
};

const useRudderStack = (): ((event: RudderstackEvents, params: object) => void) => {
  return trackRudderStackEvent;
};

export default useRudderStack;

export enum RudderstackEvents {
  ViewInvestigation = 'grafana_machine_learning_viewed_investigation',
  ViewAnalysis = 'grafana_machine_learning_viewed_analysis',
  CreateInvestigationModalOpened = 'grafana_machine_learning_create_investigation_modal_opened',
  RerunInvestigationModalOpened = 'grafana_machine_learning_rerun_investigation_modal_opened',
  CreateInvestigationModalClosedWithoutCreating = 'grafana_machine_learning_create_investigation_modal_closed_without_creating',
  CreateInvestigation = 'grafana_machine_learning_create_investigation',
  UsefulSiftThumbsUp = 'grafana_machine_learning_useful_sift_thumbs_up',
  UsefulSiftThumbsDown = 'grafana_machine_learning_useful_sift_thumbs_down',
}

export function analysisContext(analysis: Analysis): object {
  return {
    analysis: {
      id: analysis.id,
      name: analysis.name,
      result: {
        interesting: analysis.result?.interesting,
        successful: analysis.result?.successful,
      },
      status: analysis.status,
    },
  };
}
