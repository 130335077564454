import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { useMeasure } from 'react-use';

import { dateTimeFormatISO, GrafanaTheme2, rangeUtil, RawTimeRange, ScopedVars, urlUtil } from '@grafana/data';
import { locationService } from '@grafana/runtime';
import { DataQuery, TimeZone } from '@grafana/schema';
import { Button, Field, LoadingBar, Modal, PanelContainer, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

import { PLUGIN_ROOT } from 'consts';
import { useAlignedPanelData } from 'hooks';
import { useInterpolatedQuery } from 'hooks/useInterpolatedQuery';
import { OutlierSensitivity } from 'projects/Outliers/Create/OutlierSensitivity';
import { OutlierStatusbar } from 'projects/Outliers/Create/OutlierStatusbar';
import { OutlierTable } from 'projects/Outliers/Create/OutlierTable';
import {
  filterDataKeepingOutliersOnly,
  getlabelColumnValues,
  sortedOutliersToEndAndReindex,
} from 'utils/utils.outlierContent';
import { getSparkRange } from 'utils/utils.sparklines';

import { ErrorAlert } from '../ErrorAlert/ErrorAlert';
import { useOutlier } from './useOutlier';

type Props = {
  query: DataQuery;
  onDismiss?: () => void;
  timeZone: TimeZone;
  scopedVars?: ScopedVars;
  timeRange: RawTimeRange;
};

// This sensitivity was arbitrarily set on a value that wont make everything outliers
// but will give reasonable outliers. Users are able to adjust this by clicking the
// accompanying button that takes them to the ML plugin
// https://grafana.com/docs/grafana-cloud/machine-learning/outlier-detection/
const defaultSensitivity = 0.65;
const algorithm = 'dbscan';

export function OutlierModal(props: Props): ReactElement | null {
  const { query, timeZone, scopedVars, timeRange: rawTimeRange, onDismiss } = props;
  const {
    loading: loadingQuery,
    error: errorQuery,
    value: interpolatedQuery,
  } = useInterpolatedQuery(query, scopedVars);
  const [sensitivity, setSensitivity] = useState(defaultSensitivity);
  const styles = useStyles2(getStyles);
  const timeRange = useMemo(() => rangeUtil.convertRawToRange(rawTimeRange), [rawTimeRange]);
  const {
    value: data,
    error: errorOutlier,
    loading: loadingOutlier,
  } = useOutlier({
    query: interpolatedQuery,
    timeZone,
    timeRange: timeRange,
    scopedVars,
    algorithm,
    sensitivity,
  });
  const alignedPanelData = useAlignedPanelData(data);
  const [ref, { width }] = useMeasure<HTMLDivElement>();

  const onCreateOutlierDetection = useCallback(() => {
    onDismiss?.();

    locationService.push({
      pathname: `${PLUGIN_ROOT}/outlier-detector/create`,
      search: urlUtil.toUrlParams({
        ds: interpolatedQuery?.datasource?.uid,
        query_params: JSON.stringify(interpolatedQuery),
        to: dateTimeFormatISO(timeRange.to),
        from: dateTimeFormatISO(timeRange.from),
        algorithm: algorithm,
        sensitivity: defaultSensitivity,
      }),
    });
  }, [timeRange, interpolatedQuery, onDismiss]);

  const error = errorQuery ?? errorOutlier;
  const loading = loadingOutlier || loadingQuery;

  if (error) {
    return (
      <>
        <ErrorAlert title="Could not detect outlier" error={error} />
        <Modal.ButtonRow>
          <Button variant="secondary" fill="outline" onClick={onDismiss}>
            Cancel
          </Button>
          <Button onClick={onCreateOutlierDetection}>Retry in Grafana ML</Button>
        </Modal.ButtonRow>
      </>
    );
  }

  const outlierCount = data?.series.filter((o) => o.fields[1]?.labels?.isOutlier === 'True').length ?? 0;
  const sortedData = sortedOutliersToEndAndReindex(alignedPanelData);
  const outlierOnlyData = filterDataKeepingOutliersOnly(sortedData);
  const labelColumnValues = getlabelColumnValues(outlierOnlyData);
  const sparkRange = getSparkRange(alignedPanelData);

  return (
    <>
      <div className={styles.sensitivityContainer}>
        <Field label="Sensitivity">
          <OutlierSensitivity enabled={!loading} sensitivity={sensitivity} onSensitivityChange={setSensitivity} />
        </Field>
      </div>
      <PanelContainer>
        <div ref={ref} className={styles.chartContainer}>
          {loading && <LoadingBar width={width} />}
          <OutlierStatusbar data={alignedPanelData} outlierCount={outlierCount} />
          {Boolean(outlierCount > 0) && (
            <OutlierTable
              alignedData={outlierOnlyData}
              sparkRange={sparkRange}
              labelColumnValues={labelColumnValues}
              selectedIndex={undefined}
              selectable={false}
            />
          )}
        </div>
      </PanelContainer>
      <Modal.ButtonRow>
        <Button variant="secondary" fill="outline" onClick={onDismiss}>
          Cancel
        </Button>
        <Button disabled={loading} onClick={onCreateOutlierDetection}>
          Create outlier detector
        </Button>
      </Modal.ButtonRow>
    </>
  );
}

function getStyles(theme: GrafanaTheme2) {
  return {
    sensitivityContainer: css({
      border: `1px solid ${theme.colors.border.weak}`,
      borderBottom: 'none',
      padding: theme.spacing(1),
    }),
    chartContainer: css({
      minHeight: '300px',
      height: 'calc(100vh - 700px)',
      padding: theme.spacing(1),
    }),
  };
}
