import React, { createContext, PropsWithChildren, useContext } from 'react';

import { Alert, Spinner } from '@grafana/ui';

import { useTenantInfo } from 'api';
import { TenantInfo } from 'types';

const TenantContext = createContext<TenantInfo | undefined>(undefined);

export function useTenant(): TenantInfo | undefined {
  return useContext(TenantContext);
}

// A provider that loads tenant information and provides it to the app
// via context.
export function TenantProvider({ children }: PropsWithChildren<{}>) {
  const { data: tenant, isLoading, isError } = useTenantInfo();

  if (isLoading) {
    return <Spinner />;
  }

  if (isError || tenant === undefined) {
    return <Alert title="Error loading"> Error loading tenant information </Alert>;
  }

  return <TenantContext.Provider value={tenant}>{children}</TenantContext.Provider>;
}
