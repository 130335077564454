import React from 'react';

import { DataFrame, DataLinkClickEvent, Field, FieldType, LoadingState, TimeRange, toDataFrame } from '@grafana/data';
import { EmbeddedScene, PanelBuilders, SceneDataNode, SceneFlexItem, SceneFlexLayout } from '@grafana/scenes';
import { TableCellDisplayMode, ThresholdsMode, TooltipDisplayMode, VizOrientation } from '@grafana/schema';

import { useScene } from 'hooks';
import { SiftModalData } from 'types';
import { getExploreLogsServicesUrl, getPrometheusExploreUrl } from 'utils/utils.url';

enum SLEResultType {
  METRIC = 'metric',
  SERVICE = 'service',
}

interface SLEResultSingle {
  name: string;
  type: SLEResultType;
  score: number;
  rank: number;
  value?: string | number;
  query?: string;
}

interface ServiceLevelExplanationsDetailsResponse {
  model: string;
  metrics: SLEResultSingle[];
  services: SLEResultSingle[];
}
// add method on this interface to transform ServiceLevelExplanationsDetailsResponse into DataFrame

const SLEResultsToDataFrame = (
  results: SLEResultSingle[],
  promDatasourceUid?: string,
  lokiDatasourceUid?: string,
  model?: string,
  timeRange?: TimeRange
): DataFrame => {
  const fields: Field[] = [
    {
      name: 'Service name',
      type: FieldType.string,
      values: results.map((result) => (result.type === SLEResultType.METRIC ? result.name.split('/')[0] : result.name)), // remove text before the first "/"
      config: {
        links:
          results[0].type === SLEResultType.METRIC
            ? [
                {
                  title: 'Explore',
                  url: '',
                  onClick: (e: DataLinkClickEvent) => {
                    const serviceName = results[e.origin.rowIndex].name.split('/')[0];
                    const lokiService = `${model?.split(':')[0]}/${serviceName}`;
                    serviceName &&
                      lokiDatasourceUid &&
                      window.open(getExploreLogsServicesUrl(lokiDatasourceUid, lokiService, timeRange), '_blank');
                  },
                },
              ]
            : [],
      },
    },
  ];

  if (results[0].type === SLEResultType.METRIC) {
    fields.push({
      name: 'Metric name',
      type: FieldType.string,
      values: results.map((result) =>
        result.type === SLEResultType.METRIC ? result.name.split('/').slice(1).join('/') : result.name
      ), // remove text before the first "/"
      config: {
        links: [
          {
            title: 'Explore',
            url: '',
            onClick: (e: DataLinkClickEvent) => {
              const query = results[e.origin.rowIndex].query;
              query &&
                promDatasourceUid &&
                window.open(getPrometheusExploreUrl(promDatasourceUid, [query], timeRange), '_blank');
            },
          },
        ],
      },
    });
    // fields.push({
    //   name: 'Actual value',
    //   type: FieldType.number,
    //   values: results.map((result) => result.value),
    //   config: {},
    // });
  }

  fields.push({
    name: 'Contribution score',
    type: FieldType.number,
    values: results.map((result) => result.score),
    config: {},
  });
  return toDataFrame({ fields });
};

interface ServiceLevelExplanationsDetails {
  response: ServiceLevelExplanationsDetailsResponse;
}
interface ServiceLevelExplanationsInnerProps {
  analysisId: string;
  detailsResponse: ServiceLevelExplanationsDetailsResponse;
  timeRange: TimeRange;
  lokiDatasourceUid: string;
  promDatasourceUid: string;
}

function ServiceLevelExplanationsInner({
  analysisId,
  detailsResponse,
  timeRange,
  lokiDatasourceUid,
  promDatasourceUid,
}: ServiceLevelExplanationsInnerProps): React.ReactElement {
  const topN = 15; // limit viz to top N, don't need to sort as the API returns sorted results
  const metricsDataNode = new SceneDataNode({
    data: {
      state: LoadingState.Done,
      timeRange: timeRange,
      series: [
        SLEResultsToDataFrame(
          detailsResponse.metrics.slice(0, topN),
          promDatasourceUid,
          lokiDatasourceUid,
          detailsResponse.model,
          timeRange
        ),
      ],
    },
  });

  const servicesDataNode = new SceneDataNode({
    data: {
      state: LoadingState.Done,
      timeRange: timeRange,
      series: [SLEResultsToDataFrame(detailsResponse.services.slice(0, topN))],
    },
  });

  const text = PanelBuilders.text()
    .setTitle('Note')
    .setOption(
      'content',
      'Red values (above zero): increasing risk of SLO breach (e.g. higher than average)' +
        '<br />' +
        'Blue values (below zero): lowering risk of SLO breach (e.g. lower than average)'
    )
    .build();

  const metricsTable = PanelBuilders.table()
    .setTitle('Metrics')
    // .setOption('sortBy', 'Contribution to SLO breach')
    .setData(metricsDataNode)
    .setCustomFieldConfig('cellOptions', {
      type: TableCellDisplayMode.ColorText,
    })
    .setThresholds({
      mode: ThresholdsMode.Absolute,
      steps: [
        { value: 0, color: 'blue' },
        { value: 0, color: 'red' },
      ],
    })
    .build();

  const servicesBarChart = PanelBuilders.barchart()
    .setOption('legend', { showLegend: false })
    .setDecimals(2)
    .setCustomFieldConfig('axisLabel', 'Contribution to SLO breach')
    .setCustomFieldConfig('axisCenteredZero', true)
    .setOption('tooltip', { mode: TooltipDisplayMode.Multi })
    .setColor({ mode: 'thresholds' })
    .setOption('colorByField', 'Contribution score')
    .setOption('barWidth', 0.8)
    .setThresholds({
      mode: ThresholdsMode.Absolute,
      steps: [
        { value: 0, color: 'blue' },
        { value: 0, color: 'red' },
      ],
    })
    .setOption('orientation', VizOrientation.Vertical)
    .setTitle('Services')
    .setData(servicesDataNode)
    .build();

  const getScene = () => {
    return new EmbeddedScene({
      body: new SceneFlexLayout({
        direction: 'column',
        children: [
          new SceneFlexItem({
            width: '100%',
            height: 100,
            body: text,
          }),
          new SceneFlexItem({
            minWidth: 400,
            width: '100%',
            height: detailsResponse.services.slice(0, topN).length * 25 + 100,
            body: servicesBarChart,
          }),
          new SceneFlexItem({
            minWidth: 400,
            width: '100%',
            height: detailsResponse.metrics.slice(0, topN).length * 25 + 100,
            body: metricsTable,
          }),
        ],
      }),
    });
  };
  // Cache the scene using the analysis ID as the key, so that
  // the data isn't refetched if the analysis is closed and re-opened.
  const scene = useScene(getScene, analysisId);
  return <scene.Component model={scene} />;
}

export function ServiceLevelExplanations({ analysis, investigation, datasources }: SiftModalData): React.ReactElement {
  if (!analysis.result.interesting) {
    return <div>No interesting service level explanations found.</div>;
  }
  const details = analysis.result.details as unknown as ServiceLevelExplanationsDetails;
  const { metrics, services } = details.response;

  if (Object.keys(metrics).length === 0 && Object.keys(services).length === 0) {
    return <div>No service level explanations found.</div>;
  }

  const investigationTimeRange = investigation.timeRange;

  return (
    <div>
      <ServiceLevelExplanationsInner
        analysisId={analysis.id}
        detailsResponse={details.response}
        timeRange={investigationTimeRange}
        lokiDatasourceUid={datasources.lokiDatasource.uid}
        promDatasourceUid={datasources.prometheusDatasource.uid}
      />
    </div>
  );
}
