import React, { useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import {
  EmbeddedScene,
  SceneComponentProps,
  SceneFlexItem,
  SceneFlexLayout,
  SceneObjectBase,
  SceneObjectState,
  SceneTimeRange,
} from '@grafana/scenes';
import { Alert, PageToolbar, Spinner, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

import { useInvestigationAnalyses } from 'api';
import { useScene } from 'hooks';
import { Analysis, Input, Investigation, LabelInput, MetricQueryInput } from 'types';

import { FailedInvestigation } from './FailedInvestigation';
import { InvestigationAnalyses } from './InvestigationAnalyses';
import { InvestigationMetadata } from './InvestigationMetadata';
import { getInvestigationTimeline } from './InvestigationTimeline';

interface ViewInvestigationProps {
  investigation: Investigation;
  refetchInvestigation: () => void;
  showTimeline: boolean;
}

interface ViewInvestigationState extends SceneObjectState {
  investigation: Investigation;
  isError: boolean;
  analyses?: Analysis[];
  isLoading: boolean;
  refetch: () => void;
  refetchInvestigation: () => void;
  showTimeline: boolean;
}

// Wrap all investigation content into a Scene object to allow better visualisation
// and future extensions.
export class InvestigationContent extends SceneObjectBase<ViewInvestigationState> {
  public static Component = InvestigationContentRenderer;
  public timeline: SceneFlexLayout | undefined;
  private $timeRange: SceneTimeRange;

  public constructor(state: ViewInvestigationState) {
    super(state);
    this.$timeRange = new SceneTimeRange({ value: state.investigation.timeRange });
    this.timeline = this.getTimeline(state);
  }

  private getTimeline({ showTimeline, investigation, analyses }: ViewInvestigationState): SceneFlexLayout | undefined {
    const inputs = investigation.inputs;
    if (inputs !== undefined && shouldShowTimeline(showTimeline, inputs)) {
      const metricQueryInputs = inputs.filter((input): input is MetricQueryInput => input.type === 'metric-query');
      const labelInputs = inputs.filter((input): input is LabelInput => input.type === 'label');

      const investigationTimeline = getInvestigationTimeline(
        metricQueryInputs,
        labelInputs,
        this.$timeRange,
        investigation.timeRange,
        investigation.datasources.prometheusDatasource.uid,
        analyses
      );
      return new SceneFlexLayout({
        $timeRange: this.$timeRange,
        children: [
          new SceneFlexItem({
            minHeight: '200px',
            body: investigationTimeline,
          }),
        ],
      });
    }
    return undefined;
  }
}

export function shouldShowTimeline(showTimeline: boolean, inputs?: Input[]): boolean {
  return showTimeline && shouldShowTimelineButton(inputs);
}

export function shouldShowTimelineButton(inputs?: Input[]): boolean {
  // check if atleast one metric-query input is available.
  const hasMetricInputs = inputs?.some((input) => input.type === 'metric-query');
  if (inputs !== undefined && hasMetricInputs) {
    return true;
  }
  return false;
}

// ContentRenderer for the investigation content, i.e. everything after the event timeline.
function InvestigationContentRenderer({ model }: SceneComponentProps<InvestigationContent>) {
  const [render, setRender] = useState(0);
  const { investigation, isError, analyses = [], isLoading, refetch, refetchInvestigation } = model.useState();
  const timeline = model.timeline;

  const styles = useStyles2(getStyles);

  if (isError) {
    return <Alert title="Failed loading">Could not load investigation</Alert>;
  }

  if (investigation.status === 'failed') {
    return <FailedInvestigation investigation={investigation} />;
  }

  if (isLoading) {
    return <Spinner />;
  }

  if (analyses[0] === undefined) {
    // if no analyses are available, refetch after 5 seconds
    // this happens when an investigation is created and the analyses are not yet available
    setTimeout(() => {
      refetch();
      // refetch isn't causing scenes to re-render
      // when results are returned, so we need to force a re-render
      setRender(render + 1);
    }, 5000);
  }

  return (
    <div className={styles.wrapper}>
      <PageToolbar className={styles.pageToolbar} />
      <main>
        <InvestigationMetadata investigation={investigation} />
        {timeline && <timeline.Component model={timeline} />}
        <InvestigationAnalyses
          investigation={investigation}
          refetchInvestigation={refetchInvestigation}
          analyses={analyses}
        />
      </main>
    </div>
  );
}

export function getInvestigationContentScene(state: ViewInvestigationState) {
  const investigationContent = new InvestigationContent(state);

  return new EmbeddedScene({
    body: new SceneFlexLayout({
      direction: 'column',
      children: [investigationContent],
    }),
  });
}

// Create a key to use when caching the scene.
//
// We need to update the contents of the scene whenever the investigation changes
// and whenever the status of any analysis changes (in case the investigation is
// running), so include those fields in the cache key.
function cacheKey(investigation: Investigation, data: Analysis[] | undefined, showTimeline: boolean): string {
  const analysesKey = data?.map((analysis) => analysis.status).join('-');
  return `${investigation.id}-${investigation.status}-${analysesKey}-${showTimeline}`;
}

export function ViewInvestigationContent({
  investigation,
  refetchInvestigation,
  showTimeline,
}: ViewInvestigationProps): JSX.Element {
  const styles = useStyles2(getStyles);

  const { isError, data: analyses, isLoading, refetch } = useInvestigationAnalyses(investigation.id);

  const getScene = () =>
    getInvestigationContentScene({
      investigation,
      isError,
      analyses,
      isLoading,
      refetch,
      refetchInvestigation,
      showTimeline,
    });
  const scene = useScene(getScene, cacheKey(investigation, analyses, showTimeline));

  return (
    <div className={styles.wrapper}>
      <scene.Component model={scene} />
    </div>
  );
}

const getStyles = (theme: GrafanaTheme2) => {
  return {
    wrapper: css`
      display: flex;
      flex-direction: column;
      .slate-query-field {
        max-height: 100px;
      }
    `,
    feedbackText: css`
      font-size: 14px;
      color: ${theme.colors.text.primary};
      width: 100%;
      align-self: center;
      display: flex;
      justify-content: space-between;
    `,
    pageToolbar: css({
      backgroundColor: 'unset',
      padding: 'unset',
      marginBottom: '16px',
    }),
  };
};
