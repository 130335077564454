import React from 'react';

import { getDefaultTimeRange, LoadingState, TimeRange } from '@grafana/data';
import {
  PanelBuilders,
  SceneComponentProps,
  SceneDataNode,
  SceneFlexItem,
  SceneFlexLayout,
  SceneObjectBase,
  SceneObjectState,
  SceneQueryRunner,
} from '@grafana/scenes';
import { DataQuery, LegendDisplayMode } from '@grafana/schema';

import { applyTableFieldOverrides } from 'utils/utils.overrides';
import { getKubernetesMonitoringUrl, KubernetesMonitoringPodOptions } from 'utils/utils.url';

import { WorkloadCrashes } from './types';
import { getPodsDataFrame } from './utils';

interface OOMKilledTabState extends SceneObjectState {
  workload: WorkloadCrashes;
  lokiUid: string;
  promUid: string;
  pyroscopeUid: string;
  timeRange: TimeRange;
  scene?: SceneFlexLayout;
}

export class OOMKilledTab extends SceneObjectBase<OOMKilledTabState> {
  public static Component = OOMKilledTabRenderer;
  constructor(state: Omit<OOMKilledTabState, 'scene'>) {
    super({ ...state, scene: createOOMKilledTabScene(state) });
  }
}

function OOMKilledTabRenderer({ model }: SceneComponentProps<OOMKilledTab>) {
  const { scene } = model.useState();
  if (scene === undefined) {
    return null;
  }
  return <scene.Component model={scene} />;
}

interface DataQueryExtended extends DataQuery {
  expr: string;
  legendFormat: string;
}

function memoryQueries(pods: string[], cluster: string, namespace: string, promUid: string): DataQueryExtended[] {
  const datasource = {
    type: 'prometheus',
    uid: promUid,
  };
  const podsRegex = pods.join('|');
  return [
    {
      refId: 'LIMIT',
      datasource,
      expr: `sum by (pod) (kube_pod_container_resource_limits{cluster="${cluster}", namespace="${namespace}", pod=~"${podsRegex}", container!="", resource="memory"})`,
      legendFormat: 'Memory Limit ({{ pod }})',
    },
    {
      refId: 'USAGE',
      datasource,
      expr: `sum by (pod) (max by (pod, container) (container_memory_rss{cluster="${cluster}", namespace="${namespace}", pod=~"${podsRegex}", container!=""}))`,
      legendFormat: 'Memory Usage ({{ pod }})',
    },
    {
      refId: 'ALLOCATION',
      datasource,
      expr: `
        max by (namespace) (
          # memory requests (in bytes)
          sum by (namespace, resource) (kube_pod_container_resource_requests{cluster="${cluster}", namespace="${namespace}", pod=~"${podsRegex}", container=~".+", resource="memory"})
          OR
          # memory usage (in bytes)
          sum by (namespace) (max by (namespace, pod, container) (container_memory_rss{cluster="${cluster}", namespace="${namespace}", pod=~"${podsRegex}", container=~".+"}))
        )
      `,
      legendFormat: 'Memory Allocation',
    },
  ];
}

function profileQuery(
  pods: string[],
  cluster: string,
  namespace: string,
  profileType: string,
  pyroscopeUid: string
): DataQuery {
  const podsRegex = pods.join('|');
  const labelSelector = `{cluster="${cluster}", namespace="${namespace}", pod=~"${podsRegex}"}`;
  return {
    refId: 'PROFILE',
    datasource: {
      type: 'grafana-pyroscope-datasource',
      uid: pyroscopeUid,
    },
    queryType: 'profile',
    query: `${profileType}${labelSelector}`,
    profileTypeId: profileType,
    groupBy: [],
    labelSelector: labelSelector,
  } as DataQuery;
}

function createOOMKilledTabScene({
  workload,
  lokiUid,
  promUid,
  pyroscopeUid,
  timeRange,
}: Omit<OOMKilledTabState, 'scene'>): SceneFlexLayout | undefined {
  if (workload.oomkilled.length === 0) {
    return undefined;
  }
  const { from, to } = timeRange;
  const { cluster, namespace } = workload;
  const pods = workload.oomkilled.map((x) => x.labels.pod);
  const memQueries = memoryQueries(pods, cluster, namespace, promUid);
  const profileType = workload.oomkilled[0].profileType ?? '';
  const hasProfilingData = workload.oomkilled[0].hasProfilingData;
  const profQueries = profileQuery(pods, cluster, namespace, profileType, pyroscopeUid);

  const podsDF = getPodsDataFrame(workload.oomkilled.map((x) => x.labels));
  const podOptions: KubernetesMonitoringPodOptions = {
    from,
    to,
    prometheusDatasourceUid: promUid,
    lokiDatasourceUid: lokiUid,
    cluster,
    namespace,
    pod: '${__value.text}',
    // These will be populated by the applyTableFieldOverrides function.
    workload: workload.name,
    // @ts-ignore-next-line
    workloadKind: '${__data.fields["Kind"].text}',
  };
  podsDF.fields[0].config.links = [
    {
      title: 'View pod in Kubernetes Monitoring',
      url: getKubernetesMonitoringUrl(podOptions),
      targetBlank: true,
    },
  ];
  const tableData = {
    series: [applyTableFieldOverrides(podsDF)],
    state: LoadingState.Done,
    timeRange: getDefaultTimeRange(),
  };

  return new SceneFlexLayout({
    children: [
      new SceneFlexItem({
        body: PanelBuilders.timeseries()
          .setData(new SceneQueryRunner({ queries: memQueries }))
          .setTitle('Pod Memory')
          .setUnit('bytes')
          .setOption('legend', {
            showLegend: true,
            displayMode: LegendDisplayMode.Table,
            calcs: ['lastNotNull', 'max'],
          })
          .build(),
        height: '300px',
        width: '100%',
      }),
      // Only show profiling data if it exists.
      ...(hasProfilingData
        ? [
            new SceneFlexItem({
              body: PanelBuilders.flamegraph()
                .setData(new SceneQueryRunner({ queries: [profQueries] }))
                .setTitle('Profiles')
                .build(),
              height: '300px',
              width: '100%',
            }),
          ]
        : []),
      new SceneFlexItem({
        body: PanelBuilders.table()
          .setData(new SceneDataNode({ data: tableData }))
          .setTitle(`${podsDF.fields[0].values.length} OOMKilled pod${podsDF.fields[0].values.length !== 1 ? 's' : ''}`)
          .build(),
        height: '300px',
        width: '100%',
      }),
    ],
    direction: 'column',
    height: '100%',
    width: '100%',
  });
}
