import React, { useState } from 'react';
import { FeatureDescription, Features } from 'react-enable';
import { QueryClient, QueryClientProvider } from 'react-query';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import useLocalStorage from 'react-use/lib/useLocalStorage';

import { dateTimeParse } from '@grafana/data';
import { FetchError, locationService } from '@grafana/runtime';
import { Alert, Button, HorizontalGroup, LoadingBar } from '@grafana/ui';

import { adminApiPost } from 'api';
import { IS_DEV } from 'consts';
import { useInputs } from 'extensions/hooks';
import { PluginExtensionContextWrapper } from 'extensions/types';
import {
  isAlertingContextWrapper,
  isAppO11yOperationContextWrapper,
  isAppO11yServiceContextWrapper,
  isCommandPaletteContextWrapper,
  isExploreContextWrapper,
  isK8sClusterContextWrapper,
  isK8sNamespaceContextWrapper,
  isK8sPodContextWrapper,
  isK8sWorkloadContextWrapper,
  isOnCallAlertGroupContextWrapper,
  isPanelContextWrapper,
} from 'extensions/utils';
import { FEATURES } from 'features';
import { useConfirmModal } from 'hooks';
import { RudderstackEvents, trackRudderStackEvent } from 'hooks/useRudderstack';
import HelpMessage, { helpLocalStorageKey } from 'projects/Sift/HelpMessage';
import RunSiftForm, { defaultNewInvestigationForm } from 'projects/Sift/RunSiftForm';
import { InvestigationMetadata } from 'projects/Sift/View/InvestigationMetadata';
import {
  Investigation,
  InvestigationFormModel,
  InvestigationSource,
  InvestigationSourceType,
  LabelInput,
  NewInvestigation,
  Input as SiftInput,
} from 'types';
import { getMutableTimeRange } from 'utils/timeRange';

const queryClient = new QueryClient();

const overrideInputs = (defaults: InvestigationFormModel, inputs: SiftInput[]): InvestigationFormModel => {
  if (!Array.isArray(inputs)) {
    return defaults;
  }
  const newLabels = [];
  const newServices = [];
  const newDatasources = [];
  const newSlos = [];
  const form = { ...defaults };
  for (const input of inputs) {
    if (input.type == null) {
      continue;
    }
    switch (input.type) {
      case 'time-range':
        const range = getMutableTimeRange(input.timeRange);
        form.start = dateTimeParse(range.from).toISOString();
        form.end = dateTimeParse(range.to).toISOString();
        break;
      case 'label':
        newLabels.push({
          name: input.label.name,
          value: input.label.value,
          type: input.label.type,
        });
        form.labels = newLabels;
        break;
      case 'metric-query':
        form.queries.push(input.metric);
        break;
      case 'service':
        newServices.push(input.service);
        form.services = newServices;
        break;
      case 'datasource':
        newDatasources.push(input.datasource);
        form.datasources = newDatasources;
        break;
      case 'slo':
        newSlos.push(input.slo);
        form.slos = newSlos;
        break;
    }
  }
  return form;
};

interface CreateInvestigationProps {
  contextWrapper: PluginExtensionContextWrapper;
  onDismiss?: () => void;
}

function sourceFromContext(wrapper?: PluginExtensionContextWrapper): InvestigationSource {
  // Get the URL of the current page.
  const url = window.location.href;

  if (isPanelContextWrapper(wrapper)) {
    const { context } = wrapper;
    return {
      type: InvestigationSourceType.Panel,
      id: `${context.dashboard.uid}/${context.id}`,
      url,
    };
  }
  if (isExploreContextWrapper(wrapper)) {
    return {
      type: InvestigationSourceType.Explore,
      id: '',
      url,
    };
  }
  if (isAlertingContextWrapper(wrapper)) {
    return {
      type: InvestigationSourceType.AlertManager,
      // TODO: this is not unique enough, but we don't have a better option right now.
      id: `${wrapper.context.instance.labels.alertname}`,
      url,
    };
  }
  if (isOnCallAlertGroupContextWrapper(wrapper)) {
    return {
      type: InvestigationSourceType.OnCallUI,
      id: `${wrapper.context.alertGroup.pk}`,
      // TODO: We are more interested in a URL that would allow us to post resolution notes back to OnCall.
      url,
    };
  }
  if (isCommandPaletteContextWrapper(wrapper)) {
    return {
      type: InvestigationSourceType.CommandPalette,
      id: '',
      url,
    };
  }
  if (isK8sClusterContextWrapper(wrapper)) {
    return {
      type: InvestigationSourceType.KubernetesCluster,
      id: wrapper.context.cluster,
      url,
    };
  }
  if (isK8sNamespaceContextWrapper(wrapper)) {
    return {
      type: InvestigationSourceType.KubernetesNamespace,
      id: `${wrapper.context.cluster}/${wrapper.context.namespace}`,
      url,
    };
  }
  if (isK8sWorkloadContextWrapper(wrapper)) {
    return {
      type: InvestigationSourceType.KubernetesWorkload,
      id: `${wrapper.context.cluster}/${wrapper.context.namespace}/${wrapper.context.workloadType}/${wrapper.context.workload}`,
      url,
    };
  }
  if (isK8sPodContextWrapper(wrapper)) {
    return {
      type: InvestigationSourceType.KubernetesPod,
      id: `${wrapper.context.cluster}/${wrapper.context.namespace}/${wrapper.context.workloadType}/${wrapper.context.workload}/${wrapper.context.pod}`,
      url,
    };
  }
  if (isAppO11yServiceContextWrapper(wrapper)) {
    return {
      type: InvestigationSourceType.AppO11yService,
      id: wrapper.context.service.job,
      url,
    };
  }
  if (isAppO11yOperationContextWrapper(wrapper)) {
    return {
      type: InvestigationSourceType.AppO11yOperation,
      id: `${wrapper.context.service.job}/${wrapper.context.operation}`,
      url,
    };
  }

  return {
    type: InvestigationSourceType.Unknown,
    id: '',
    url,
  };
}

function nameHint(wrapper?: PluginExtensionContextWrapper, inputs?: SiftInput[]): string | undefined {
  if (isPanelContextWrapper(wrapper)) {
    const { context } = wrapper;
    return `Investigation of panel '${context.title}' (dashboard '${context.dashboard.title}')`;
  }
  if (isExploreContextWrapper(wrapper)) {
    const labels = inputs
      ?.filter((i): i is LabelInput => i.type === 'label')
      .map((i) => `${i.label.name}="${i.label.value}"`);
    if (labels === undefined || labels.length === 0) {
      return `Investigation of Explore view`;
    }
    return labels.slice(1).reduce((acc, x) => {
      const concat = `${acc}, ${x}`;
      return concat.length > 100 ? acc : concat;
    }, `Investigation of ${labels[0]}`);
  }
  if (isAlertingContextWrapper(wrapper)) {
    const { context } = wrapper;
    if (context.instance.labels.alertname === undefined) {
      return undefined;
    }
    return `Investigation of alert ${context.instance.labels.alertname}`;
  }
  if (isOnCallAlertGroupContextWrapper(wrapper)) {
    return `Investigation of OnCall alert group ${wrapper.context.alertGroup.render_for_web.title}`;
  }
  if (isK8sClusterContextWrapper(wrapper)) {
    return `Investigation of Kubernetes cluster ${wrapper.context.cluster}`;
  }
  if (isK8sNamespaceContextWrapper(wrapper)) {
    return `Investigation of Kubernetes namespace ${wrapper.context.namespace} in cluster ${wrapper.context.cluster}`;
  }
  if (isK8sWorkloadContextWrapper(wrapper)) {
    return `Investigation of Kubernetes workload ${wrapper.context.namespace}/${wrapper.context.workload} in cluster ${wrapper.context.cluster}`;
  }
  if (isK8sPodContextWrapper(wrapper)) {
    return `Investigation of Kubernetes pod ${wrapper.context.namespace}/${wrapper.context.pod} in cluster ${wrapper.context.cluster}`;
  }
  if (isAppO11yServiceContextWrapper(wrapper) || isAppO11yOperationContextWrapper(wrapper)) {
    return `Investigation of service ${wrapper.context.service.job}`;
  }
  return undefined;
}

export function CreateInvestigationModalInner({ contextWrapper, onDismiss }: CreateInvestigationProps): JSX.Element {
  const i = useInputs(contextWrapper);
  const { loading, value: inputs } = i;

  const [createdInvestigation, setCurrentInvestigation] = useState<Investigation | undefined>(undefined);

  const uncreatedOnDismiss = () => {
    if (createdInvestigation === undefined) {
      trackRudderStackEvent(RudderstackEvents.CreateInvestigationModalClosedWithoutCreating, {});
    }
    onDismiss && onDismiss();
  };

  if (loading) {
    return (
      <>
        <LoadingBar width={100} />
        Loading inputs...
      </>
    );
  }

  const defaultNewInvestigation = defaultNewInvestigationForm();
  // Remove any labels from the default form; we no longer want to add them.
  defaultNewInvestigation.labels = [];
  // note: if loading inputs failed, we just use the defaults.
  const newInvestigation = inputs != null ? overrideInputs(defaultNewInvestigation, inputs) : defaultNewInvestigation;
  newInvestigation.name = nameHint(contextWrapper, inputs) ?? '';
  newInvestigation.investigationSource = sourceFromContext(contextWrapper);

  const onViewInvestigation = () => {
    if (createdInvestigation !== undefined) {
      onDismiss && onDismiss();
      locationService.push({ pathname: `/a/grafana-ml-app/investigations/${createdInvestigation.id}` });
    }
  };

  return (
    <>
      {createdInvestigation === undefined && (
        <CreateInvestigationForm
          newInvestigation={newInvestigation}
          setCurrentInvestigation={setCurrentInvestigation}
          onDismiss={uncreatedOnDismiss}
        />
      )}
      {createdInvestigation !== undefined && (
        <>
          <h3>Investigation created</h3>
          <h6>{createdInvestigation.name}</h6>
          <InvestigationMetadata investigation={createdInvestigation} />
          <HorizontalGroup justify="flex-end" align="flex-end" spacing="sm">
            <Button onClick={onDismiss} variant="secondary">
              Cancel
            </Button>
            <Button icon="external-link-alt" onClick={onViewInvestigation} variant="primary">
              View Investigation
            </Button>
          </HorizontalGroup>
        </>
      )}
    </>
  );
}

interface CreateInvestigationFormProps {
  newInvestigation: InvestigationFormModel;
  setCurrentInvestigation: (investigation: Investigation) => void;
  onDismiss?: () => void;
}

function CreateInvestigationForm({
  onDismiss,
  newInvestigation,
  setCurrentInvestigation,
}: CreateInvestigationFormProps): JSX.Element {
  const [{ loading, error }, onSubmit] = useAsyncFn(
    async (newInvestigation: NewInvestigation) => {
      let response;
      try {
        response = await adminApiPost(`/sift/api/v1/investigations`, {
          data: newInvestigation,
        });
      } catch (error) {
        throw new Error(`Failed to create investigation: ${(error as FetchError).data.message}`);
      }
      if (!response.ok) {
        throw response.data;
      }
      setCurrentInvestigation(response?.data?.data as Investigation);
    },
    [newInvestigation, setCurrentInvestigation]
  );
  const [confirmAbandon, ConfirmAbandon] = useConfirmModal(async () => onDismiss && onDismiss());
  const [showAlert, setShowAlert] = useState(true);
  // Show the help message conditionally based on the value of the boolean in local storage.
  // Note: there is currently no way for the user to clear this or set it to 'true', so after
  // they dismiss the help message once it will never show again unless they clear their storage.
  const [showHelp, setShowHelp] = useLocalStorage<boolean>(helpLocalStorageKey, true);

  return (
    <>
      <ConfirmAbandon icon="exclamation-triangle" title="Unsaved changes" actionText="Abandon">
        <div>Do you really wish to abandon this investigation?</div>
      </ConfirmAbandon>
      {loading && <LoadingBar width={100} />}
      {showHelp && <HelpMessage onRemove={() => setShowHelp(false)} />}
      {showAlert && (
        <Alert title="Check investigation inputs" severity="info" onRemove={() => setShowAlert(false)}>
          Investigation inputs have been automatically discovered, but you may wish to double check them first.
        </Alert>
      )}
      {error && (
        <Alert title="Failed to create investigation" severity="error">
          {error.message}
        </Alert>
      )}
      <RunSiftForm defaults={newInvestigation} onSubmit={onSubmit} onClose={confirmAbandon} />
    </>
  );
}

export function CreateInvestigationModal(props: CreateInvestigationProps): JSX.Element {
  const features = FEATURES.map((feature) => {
    return feature as FeatureDescription<string>;
  });
  return (
    <QueryClientProvider client={queryClient}>
      <Features features={features} storage={IS_DEV ? window.localStorage : window.sessionStorage}>
        <CreateInvestigationModalInner {...props} />
      </Features>
    </QueryClientProvider>
  );
}
