import { applyFieldOverrides, createDataFrame, createTheme, DataFrame, ScopedVars } from '@grafana/data';

export function applyTableFieldOverrides(dataFrame: DataFrame): DataFrame {
  const dataFrames = applyFieldOverrides({
    data: [dataFrame],
    fieldConfig: {
      defaults: {},
      overrides: [],
    },
    // This is necessary to make text variables show up in tables, though I am
    // not completely sure why. It is adapted from getDefaultDataFrame() in
    // https://github.com/grafana/grafana/blob/main/packages/grafana-ui/src/components/Table/Table.test.tsx.
    replaceVariables: (value: string, vars?: ScopedVars, _format?: Function | string): string => {
      const scopedVal = vars?.__dataContext?.value;
      if (vars === undefined || scopedVal === undefined || scopedVal.rowIndex === undefined) {
        return value;
      }
      // Replace any instances of __value.text with the actual value from the field.
      const { field, rowIndex, frame } = scopedVal;
      let replaced = value.replaceAll('${__value.text}', field.values[rowIndex] as string);
      // Replace any instances of __data.fields["<field name>"].text with the actual value from the field.
      for (const f of frame.fields) {
        const toReplace = '${__data.fields["' + f.name + '"].text}';
        replaced = replaced.replaceAll(toReplace, f.values[rowIndex] as string);
      }
      return replaced;
    },
    theme: createTheme(),
  });

  return dataFrames[0] ?? createDataFrame({ fields: [] });
}
