import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Box, LinkButton, Stack, Text, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

import logo from '../../../assets/logo.svg';

export const AlertingHomepageCallout = () => {
  const styles = useStyles2(getStyles);

  return (
    <div className={styles.container}>
      <Box paddingBottom={1} flex={0}>
        <Stack direction="row" justifyContent="center">
          <img src={logo} className={styles.logo} alt="Machine learning logo" />
          <Text element="h4">Machine Learning</Text>
        </Stack>
      </Box>
      <Box flex={1}>Create dynamic alerts based on metric forecasts and outliers</Box>
      <Box flex={0}>
        <LinkButton href="/a/grafana-ml-app/home" fill="text">
          Go to Machine Learning App
        </LinkButton>
      </Box>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  container: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${theme.spacing(1)};
    height: 100%;
  `,
  logo: css`
    width: ${theme.spacing(3)};
    height: ${theme.spacing(3)};
  `,
});
