import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { DataSourceInstanceSettings } from '@grafana/data';
import { config } from '@grafana/runtime';
import { ButtonVariant, LinkButton, Menu } from '@grafana/ui';

import { css } from '@emotion/css';

import { Outlier } from 'api/types';
import { PLUGIN_ROOT } from 'consts';
import { useMlDataSource } from 'hooks/useSupportedDatasources';
import { tryGte } from 'utils/utils.general';

/// Create a URL to the 'Create Alert' screen with some defaults for the form.
function newOutlierAlertUrl(outlier: Outlier, mlDatasource: DataSourceInstanceSettings, returnTo: string): string {
  // In the future we might want to set the interval and for/every clause in the Outlier UX
  // For now, use the alerting default (for 1 min every 5 min)
  const interval = 60;
  const evaluateForSeconds = 5 * interval;
  const evaluateFor = `${evaluateForSeconds / 60}m`;

  const [instant, reducer] = tryGte(config.buildInfo.version, '8.4.0') ? [false, 'last'] : [true, 'mean'];

  const defaultsJob = {
    type: 'grafana',
    name: `${outlier.name} (Grafana ML Outliers)`,
    // Grouping was introduced in Grafana 8.5+
    group: 'grafana-ml-outliers',
    condition: 'B',
    evaluateFor,
    queries: [
      {
        refId: 'A',
        queryType: '',
        relativeTimeRange: { from: 3600, to: 0 },
        datasourceUid: mlDatasource.uid,
        model: {
          expr: `${outlier.metric}:outliers`,
          legendFormat: '',
          exemplar: false,
          instant,
          datasource: { type: 'prometheus', uid: outlier.datasourceUid },
          refId: 'A',
          interval: `${interval / 60}m`,
        },
      },
      {
        refId: 'B',
        queryType: '',
        // "-100" is a special datasourceUid understood by Grafana to mean a server side expression
        // rather than a query.
        datasourceUid: '-100',
        model: {
          conditions: [
            {
              evaluator: {
                params: [0, 0],
                type: 'gt',
              },
              operator: {
                type: 'and',
              },
              query: {
                params: [],
              },
              reducer: {
                params: [],
                type: 'avg',
              },
              type: 'query',
            },
          ],
          datasource: {
            type: '__expr__',
            uid: '__expr__',
          },
          expression: 'A',
          hide: false,
          reducer,
          refId: 'B',
          type: 'reduce',
        },
      },
    ],
    annotations: [
      { key: 'grafana-ml-outlier-detector-id', value: outlier.id },
      { key: 'grafana-ml-outlier-detector-name', value: outlier.name },
      { key: 'grafana-ml-outlier-detector-metric', value: outlier.metric },
      { key: 'grafana-ml-outlier-detector-description', value: outlier.description ?? null },
    ],
  };
  returnTo = returnTo.startsWith('/') ? returnTo : `/${returnTo}`;
  return `/alerting/new?defaults=${encodeURIComponent(JSON.stringify(defaultsJob))}&returnTo=${encodeURIComponent(
    PLUGIN_ROOT + returnTo
  )}`;
}

interface CreateOutlierAlertButtonProps {
  outlier: Outlier;
  alertingEnabled: boolean;
  returnTo: string;
  variant?: ButtonVariant;
  kind?: 'button' | 'menu-item';
}

export const CreateOutlierAlertButton: FC<CreateOutlierAlertButtonProps> = ({
  outlier,
  alertingEnabled,
  returnTo,
  variant,
  kind = 'button',
}) => {
  const navigate = useNavigate();
  const mlDataSource = useMlDataSource();
  // Don't let users create alerts unless:
  // - Grafana Alerting is enabled
  // - the ML datasource exists
  // - the job can be viewed.
  const alertButtonEnabled = alertingEnabled && mlDataSource !== undefined;
  const title = !alertingEnabled
    ? 'Grafana Alerting must be enabled to create an alert.'
    : mlDataSource == null
    ? 'Grafana ML datasource not found, please refresh the plugin.'
    : // Success case.
      'Create an alert using Grafana Alerting';
  const href =
    alertButtonEnabled && mlDataSource !== undefined ? newOutlierAlertUrl(outlier, mlDataSource, returnTo) : undefined;

  if (kind === 'menu-item' && href !== undefined) {
    return (
      <Menu.Item
        label="Create alert"
        icon="bell"
        onClick={() => {
          navigate(href, { replace: true });
        }}
      />
    );
  }

  return (
    <LinkButton
      key="create-alert"
      href={href}
      disabled={!alertButtonEnabled}
      className={alertButtonEnabled ? styles.empty : styles.disabledWithTooltip}
      title={title}
      variant={variant ?? 'secondary'}
    >
      Create Alert
    </LinkButton>
  );
};

const styles = {
  empty: css``,
  disabledWithTooltip: css`
    opacity: 0.6;
    cursor: not-allowed;
    pointer-events: auto;
  `,
};
