import React, { useCallback, useEffect, useState } from 'react';
import { useLocalStorage } from 'react-use';

import { AppEvents } from '@grafana/data';
import { getAppEvents, locationService } from '@grafana/runtime';
import { Modal, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

import { useCreateInvestigation } from 'api';
import { PLUGIN_ROOT } from 'consts';
import useRudderStack, { RudderstackEvents } from 'hooks/useRudderstack';
import { InvestigationFormModel, NewInvestigation } from 'types';

import HelpMessage, { helpLocalStorageKey } from './HelpMessage';
import RunSiftForm from './RunSiftForm';

interface SiftRunModalProps {
  show: boolean;
  onClose: () => void;
  overrideSubmit?: boolean;
  onSubmit?: (form?: NewInvestigation) => void;
  defaults?: InvestigationFormModel;
  title?: string;
  // Set this to true to force the modal to show or hide the help message.
  // Otherwise, the value of `helpLocalStorageKey` in localStorage will
  // be used.
  // Note that if the user dismisses the help message, the local storage
  // value will still be set and used for subsequent renders.
  showHelp?: boolean;
}

function RunSiftModal({
  show,
  showHelp: showHelpOverride,
  onClose,
  overrideSubmit,
  onSubmit: onSubmitProp,
  defaults,
  title,
}: SiftRunModalProps): React.ReactElement | null {
  const styles = useStyles2(getStyles);
  const trackRudderStackEvent = useRudderStack();
  // Show the help message conditionally based on the value of the boolean in local storage.
  // Note: there is currently no way for the user to clear this or set it to 'true', so after
  // they dismiss the help message once it will never show again unless they clear their storage.
  let [showHelp, setShowHelp] = useLocalStorage<boolean>(helpLocalStorageKey, true);
  // If the parent passed `showHelp`, we need to honour that regardless of what the local storage
  // value is initially. However, if the user dismisses the button, we need to start honouring
  // the local storage value. Use this state to track whether the help has been dismissed.
  let [helpDismissed, setHelpDismissed] = useState(false);
  const shouldShowHelp = showHelpOverride !== undefined && !helpDismissed ? showHelpOverride : showHelp;
  const dismissHelp = useCallback(() => {
    setHelpDismissed(true);
    setShowHelp(false);
  }, [setHelpDismissed, setShowHelp]);
  const { mutateAsync: createInvestigation } = useCreateInvestigation();
  const onSubmit = (form: NewInvestigation) => {
    // setting override submit allows us to completely override the submit action
    // with a function provided by the parent component
    // this is used in extension points
    if (overrideSubmit && onSubmitProp !== undefined) {
      onSubmitProp(form);
    } else {
      createInvestigation(form, {
        onSuccess(resp) {
          locationService.push({
            pathname: `${PLUGIN_ROOT}/investigations/${resp.id}`,
          });
          getAppEvents().publish({
            type: AppEvents.alertSuccess.name,
            payload: ['Investigation created', `Investigation has been created and will be analysed shortly.`],
          });
        },
      });
      onSubmitProp?.(form);
    }
  };

  useEffect(() => {
    if (show) {
      trackRudderStackEvent(RudderstackEvents.CreateInvestigationModalOpened, {
        investigationSourceType: defaults?.investigationSource.type,
      });
    }
  }, [show, defaults, trackRudderStackEvent]);

  if (!show) {
    return null;
  }

  return (
    <Modal
      className={styles.modal}
      title={
        <div className={styles.modalHeader}>
          <div className={styles.modalTitle}>{title ?? 'New investigation'}</div>
        </div>
      }
      isOpen={show}
      closeOnEscape
      onDismiss={() => {
        onClose();
      }}
      onClickBackdrop={() => {
        onClose();
      }}
    >
      {shouldShowHelp && <HelpMessage onRemove={dismissHelp} />}
      <RunSiftForm defaults={defaults} onClose={onClose} onSubmit={onSubmit} />
    </Modal>
  );
}

const getStyles = () => {
  return {
    modal: css`
      width: 650px;
      min-height: 500px;
      & > div:first-child {
        border-bottom: none;
      }
    `,
    modalHeader: css`
      display: flex;
      gap: 16px;
      flex-direction: row;
      width: 100%;
    `,
    modalTitle: css`
      white-space: nowrap;
      font-size: 18px;
    `,
  };
};

export { RunSiftModal, SiftRunModalProps };
