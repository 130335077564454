import React, { useLayoutEffect, useRef } from 'react';

import { Field, GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';
import simplify from 'simplify-js';
import uPlot from 'uplot';

interface SparklineProps {
  data: Field<number>;
  fieldName: string;
  height: number;
  width: number;
  minY: number;
  maxY: number;
  lineColor: string;
  selectedIndex?: number;
  isSelected?: boolean;
  onClick?: (index: number | undefined) => void;
}

const getOptions = (width: number, height: number, minY: number, maxY: number, lineColor: string) => ({
  width,
  height,
  pxAlign: false,
  cursor: {
    show: false,
  },
  legend: {
    show: false,
  },
  scales: {
    x: {
      time: false,
    },
    y: {
      auto: false,
      range: [minY, maxY] as [number | null, number | null],
    },
  },
  axes: [
    {
      show: false,
    },
    {
      show: false,
    },
  ],
  series: [
    {},
    {
      stroke: lineColor,
    },
  ],
});

export function Sparkline({
  data,
  height,
  width,
  minY = 0,
  maxY = 200,
  lineColor,
  selectedIndex,
  isSelected = false,
  onClick,
}: SparklineProps): JSX.Element {
  const chartRef = useRef<uPlot | null>(null);
  const targetRef = useRef<HTMLDivElement>(null);
  const styles = useStyles2((theme) => getStyles(theme, Boolean(onClick)));

  useLayoutEffect(() => {
    const cleanedBuffer = data.values.filter(Number.isFinite);

    const yMinMax = cleanedBuffer.reduce(
      (minMax: { min: number | undefined; max: number | undefined }, yVal: number) => {
        minMax.min = minMax.min === undefined || minMax.min > yVal ? yVal : minMax.min;
        minMax.max = minMax.max === undefined || minMax.max < yVal ? yVal : minMax.max;
        return minMax;
      },
      { min: undefined, max: undefined }
    );

    const yMin = yMinMax.min ?? 0;
    const yMax = yMinMax.max ?? 0;

    const yScale = (y: number) => {
      return ((y - yMin) / (yMax - yMin)) * 1000;
    };

    const yUnScale = (y: number) => {
      return (y / 1000) * (yMax - yMin) + yMin;
    };

    const points = cleanedBuffer.reduce((xy: Array<{ x: number; y: number }>, YValue: number, index: number) => {
      xy[index] = {
        x: index,
        y: yScale(Number(YValue)),
      };
      return xy;
    }, new Array(cleanedBuffer.length));

    const sparkPoints = simplify(points, 20);

    const finalPoints = sparkPoints.reduce(
      (xy: { x: number[]; y: number[] }, p: { x: number; y: number }, index: number) => {
        xy.x[index] = p.x;
        xy.y[index] = yUnScale(Number(p.y));
        return xy;
      },
      {
        x: new Array(sparkPoints.length),
        y: new Array(sparkPoints.length),
      }
    );

    const spark = new uPlot(
      getOptions(width, height, minY, maxY, lineColor),
      [finalPoints.x, finalPoints.y],
      targetRef.current as HTMLDivElement
    );
    chartRef.current = spark;

    return () => {
      chartRef.current = null;
      spark.destroy();
    };
  }, [width, height, data.values, minY, maxY, isSelected, targetRef, chartRef, lineColor]);

  return (
    <div className={isSelected ? styles.selected : styles.unselected} style={{ width: width }}>
      <div
        onClick={() => {
          if (onClick !== undefined) {
            // return undefined if the item is already selected so we toggle it off
            onClick(isSelected ? undefined : selectedIndex ?? undefined);
          }
        }}
        ref={targetRef}
      />
    </div>
  );
}

const getStyles = (theme: GrafanaTheme2, selectable: boolean) => {
  return {
    selected: css({
      margin: '2px 2px 2px 2px',
      cursor: selectable ? 'pointer' : 'auto',
      backgroundColor: `${theme.isDark ? '#22252b' : '#f4f5f5'}`,
    }),
    unselected: css({
      margin: '2px 2px 2px 2px',
      cursor: selectable ? 'pointer' : 'auto',
    }),
  };
};
