import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { AppEvents, DataSourceInstanceSettings } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { Button, Field, IconButton, Input, Modal, TextArea, useStyles } from '@grafana/ui';

import { css } from '@emotion/css';

import { useCreateMetricJob, useValidateNewJob } from 'api';
import { CustomLabels } from 'components/FormFields/CustomLabels';
import { validateLabel, validateUniqueName } from 'components/FormFields/validators';
import { PLUGIN_ROOT } from 'consts';
import { DataQueryWithExpression, ForecastLabels, FullFormModel } from 'types';
import { createJobRequestFromFormModel } from 'utils';
import { slugifyName } from 'utils/utils.jobs';

import { useForecastingState } from '../useForecastingState';

interface CreateJobModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  datasource: DataSourceInstanceSettings;
  queryParams?: DataQueryWithExpression;
  modelForm: FullFormModel;
  attachedHolidays: string[];
}

export const CreateJobModal: React.FC<CreateJobModalProps> = ({
  isOpen,
  onDismiss,
  datasource,
  modelForm,
  queryParams,
  attachedHolidays,
}) => {
  const { mutateAsync: createJob } = useCreateMetricJob();
  const { mutateAsync: validateJob } = useValidateNewJob();
  const { data, setShowCreateModal } = useForecastingState();
  const { modelForm: submitForm } = data;
  const navigate = useNavigate();
  const [showEditMetric, setShowEditMetric] = useState(false);
  const { container } = useStyles(getStyles);
  const [, setLabelValues] = useState([]);

  const initialLabels = Object.entries(modelForm.customLabels || {}).map(([prop, value]) => ({
    prop,
    value,
  })) as ForecastLabels[];

  const {
    control,
    register,
    getValues,
    formState: { errors, isValidating, isSubmitting },
  } = useForm<FullFormModel>({
    mode: 'all',
    shouldFocusError: true,
    defaultValues: {
      ...modelForm,
      labels: initialLabels,
    },
  });

  const areLabelsValid = (labels: ForecastLabels[] | undefined) => {
    return (
      labels?.find((label: ForecastLabels) => {
        const validation = validateLabel(label.prop);
        if (validation.invalid) {
          return true;
        }
        return false;
      }) === undefined
    );
  };

  const onSave = useCallback(
    async (form: FullFormModel) => {
      void createJob(createJobRequestFromFormModel(form, datasource), {
        onSuccess() {
          setShowCreateModal(false);
          navigate(`${PLUGIN_ROOT}/metric-forecast`);
          getAppEvents().publish({
            type: AppEvents.alertSuccess.name,
            payload: ['Forecast created', `Forecast '${form.name}' has been created and will be trained shortly.`],
          });
        },
      });
    },
    [setShowCreateModal, createJob, datasource, navigate]
  );

  if (modelForm == null || datasource == null) {
    return null;
  }

  const isValid = (getValues('name') ?? '') !== '';
  const metricName = slugifyName(getValues('name'));

  return (
    <Modal
      className={container}
      isOpen={isOpen}
      onDismiss={onDismiss}
      onClickBackdrop={onDismiss}
      title="Create forecast"
    >
      <form>
        <Field label="Name" invalid={Boolean(errors.name)} error={errors.name?.message}>
          <Input
            {...register('name', {
              required: 'name must be provided',
            })}
          />
        </Field>
        <Field label="Description" invalid={Boolean(errors.description)} error={errors.description?.message}>
          <TextArea {...register('description')} placeholder="Add a description for your forecast..." />
        </Field>
        <Field
          label={
            showEditMetric ? (
              'Metric name'
            ) : (
              <label className={styles.metricLabel}>
                <span>Metric name: {metricName}</span>
                <IconButton
                  className={styles.editMetricIcon}
                  type="button"
                  form="Not-Default-For-This-Form"
                  name="edit"
                  size="sm"
                  tooltip="Edit the metric name"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowEditMetric(true);
                  }}
                />
              </label>
            )
          }
          invalid={Boolean(errors.metric)}
          error={errors.metric?.message}
        >
          {showEditMetric ? (
            <Input
              {...register('metric', {
                required: 'metric is required',
                validate: (name) => validateUniqueName(name, validateJob),
              })}
            />
          ) : (
            <input
              type="hidden"
              {...register('metric', {
                required: 'metric is required',
                validate: (name) => validateUniqueName(name, validateJob),
              })}
            />
          )}
        </Field>
        <input type="hidden" {...register('parameters', { required: 'parameters required' })} />
        <CustomLabels
          getValues={getValues}
          control={control}
          register={register}
          onChange={(v) => {
            console.log('label change', v);
            setLabelValues(v);
          }}
        />
        <div style={{ display: 'flex', gap: '4px' }} data-element="forecasting-confirm-create-buttons">
          <Button
            key="forecast-create-button"
            type="button"
            disabled={!areLabelsValid(getValues('labels')) || !isValid || isValidating || isSubmitting}
            onClick={() => {
              // consolidate form output
              const form: FullFormModel = {
                name: getValues('name'),
                description: getValues('description'),
                labels: getValues('labels'),
                metric: metricName,
                parameters: submitForm.parameters,
                holidays: attachedHolidays,
                query: {
                  key: 'A',
                  value: queryParams,
                },
              };

              onSave(form);
            }}
          >
            Confirm
          </Button>
          <Button variant="secondary" onClick={onDismiss}>
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  );
};

function getStyles() {
  return {
    container: css`
      width: 500px;
    `,
  };
}

const styles = {
  metricLabel: css`
    font-size: 12px;
    font-weight: 500;
    line-height: 1.25;
    display: inline-flex;
    align-items: center;
  `,
  editMetricIcon: css`
    margin-left: 10px;
  `,
};
