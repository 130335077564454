import React, { PropsWithChildren } from 'react';

import { ProviderWrapper } from 'App';

import { SiftModal, SiftModalContext } from 'projects/Sift/SiftModal';

export function SiftAnalysisModal(props: PropsWithChildren<{ context?: SiftModalContext }>) {
  const context: SiftModalContext = {
    analysisId: props?.context?.analysisId ?? '',
    investigationId: props?.context?.investigationId ?? '',
    show: props?.context?.show ?? false,
    onOpen: props?.context?.onOpen,
    onClose: props?.context?.onClose,
    modalButtons: props?.context?.modalButtons,
  };

  return (
    <ProviderWrapper>
      <SiftModal context={context} />
    </ProviderWrapper>
  );
}
