import React, { ReactElement, useCallback, useState } from 'react';
import { useMeasure } from 'react-use';

import { ScopedVars, urlUtil } from '@grafana/data';
import { locationService } from '@grafana/runtime';
import { DataQuery, TimeZone } from '@grafana/schema';
import { Button, HorizontalGroup, LoadingBar, Modal, useStyles2, VerticalGroup } from '@grafana/ui';

import { css } from '@emotion/css';

import { JobGraph } from 'components/JobGraph';
import { useInterpolatedQuery } from 'hooks/useInterpolatedQuery';

import { ErrorAlert } from '../ErrorAlert/ErrorAlert';
import { FutureTimeRangePicker, useFutureTimeOptions } from '../FutureTimeRangePicker';
import { useForecast } from './useForecast';

type Props = {
  query: DataQuery;
  onDismiss?: () => void;
  timeZone: TimeZone;
  scopedVars?: ScopedVars;
};

const noop = () => {};

export function ForecastModal(props: Props): ReactElement | null {
  const { query, timeZone, onDismiss, scopedVars } = props;
  const {
    loading: loadingQuery,
    error: errorQuery,
    value: interpolatedQuery,
  } = useInterpolatedQuery(query, scopedVars);
  const styles = useStyles2(getStyles);
  const { timeOptions, defaultTimeRange } = useFutureTimeOptions(timeZone);
  const [timeRange, setTimeRange] = useState(defaultTimeRange);
  const {
    loading: loadingForecast,
    error: errorForecast,
    value: data,
  } = useForecast({ query: interpolatedQuery, timeRange, scopedVars, timeZone });
  const [ref, { width }] = useMeasure<HTMLDivElement>();

  const loading = loadingForecast || loadingQuery;
  const error = errorQuery ?? errorForecast;

  const onCreateForecast = useCallback(() => {
    onDismiss?.();

    locationService.push({
      pathname: '/a/grafana-ml-app/metric-forecast/create',
      search: urlUtil.toUrlParams({
        ds: interpolatedQuery?.datasource?.uid,
        query_params: JSON.stringify(interpolatedQuery),
      }),
    });
  }, [interpolatedQuery, onDismiss]);

  if (error) {
    return (
      <>
        <ErrorAlert title="Could not create forecast" error={error} />
        <Modal.ButtonRow>
          <Button variant="secondary" fill="outline" onClick={onDismiss}>
            Cancel
          </Button>
          <Button onClick={onCreateForecast}>Retry in Grafana ML</Button>
        </Modal.ButtonRow>
      </>
    );
  }

  return (
    <VerticalGroup>
      <FutureTimeRangePicker
        onChangeTimeRange={setTimeRange}
        options={timeOptions}
        timeRange={timeRange}
        timeZone={timeZone}
      />
      <div ref={ref} className={styles.chartContainer}>
        {loading && <LoadingBar width={width} />}
        <JobGraph
          timeRange={timeRange}
          timeZone={timeZone}
          maxDataPoints={100}
          isRefreshing={false}
          data={data}
          onCancelQuery={noop}
          onChangeMaxDataPoints={noop}
          onChangeTimeRange={setTimeRange}
          onRunQuery={noop}
        />
      </div>
      <HorizontalGroup justify="flex-end" spacing="md" wrap>
        <Button variant="secondary" fill="outline" onClick={onDismiss}>
          Cancel
        </Button>
        <Button disabled={loading} onClick={onCreateForecast}>
          Create forecast
        </Button>
      </HorizontalGroup>
    </VerticalGroup>
  );
}

function getStyles() {
  return {
    chartContainer: css({
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100vh - 600px)',
      minHeight: '400px',
      width: '100%',
    }),
  };
}
