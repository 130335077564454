import React from 'react';

import { getTimeZone, getTimeZoneInfo, GrafanaTheme2 } from '@grafana/data';
import { Alert, Button, ErrorBoundary, Stack, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

import { Badge } from 'components/Badge';
import { useFeature } from 'hooks';
import { Analysis, AnalysisStatus, Investigation } from 'types';

import AnalysisDetails from '../AnalysisDetails';
import FeedbackButtons, { useFeedback } from '../Components/FeedbackButtons';
import { SiftModal } from '../SiftModal';
import { AnalysisTooltip } from './AnalysisTooltip';

interface AnalysisBadgeProps {
  status: AnalysisStatus;
  interesting: boolean;
  successful: boolean;
  message: string;
}

function AnalysisBadge({ status, interesting, successful, message }: AnalysisBadgeProps): React.ReactElement {
  if (status === 'skipped') {
    return <Badge badgeType="skipped" tooltipText="Analysis was skipped" />;
  }
  if (status !== 'finished') {
    return <Badge badgeType="running" tooltipText="Analysis is running" />;
  }
  return (
    <>
      {successful || <Badge badgeType="error" tooltipText={message} />}
      {interesting && <Badge badgeType="interesting" tooltipText={message} />}
    </>
  );
}

export interface AnalysisResultsProps {
  analysis: Analysis;
  investigation: Investigation;
}

export interface AnalysisMessageProps {
  analysis: Analysis;
}

// This function is used to customise the message displayed for each analysis.
// So far it only implements a customisation for HTTPErrorSeries analysis.
function CustomisedMessage({ analysis }: AnalysisMessageProps): JSX.Element {
  const styles = useStyles2(getStyles);
  let message = analysis.result.message;

  if (analysis.name === 'HTTPErrorSeries') {
    const details = analysis.result.details;
    if (details && typeof details === 'object' && 'windowStart' in details) {
      const windowStart = details?.windowStart?.toString() ?? '';
      if (windowStart) {
        const timezone = getTimeZone();
        const timezoneinfo = getTimeZoneInfo(timezone, new Date(windowStart).getTime());
        const time = convertToTimeZone(new Date(windowStart), timezoneinfo?.ianaName ?? 'UTC');
        message += ` starting at ${time} ${timezoneinfo?.abbreviation ?? ''}`;
      }
    }
  }

  return <div className={styles.message}>{message}</div>;
}

export function AnalysisResults({ investigation, analysis }: AnalysisResultsProps): JSX.Element {
  const styles = useStyles2(getStyles);
  const showModalButton = useFeature('ShowSiftModalButton');
  const [showModal, setShowModal] = React.useState(false);
  const { status, result } = analysis;

  const [feedback, setFeedback] = useFeedback(
    `grafana.ml.sift.investigation.${investigation.id}.analysis.${analysis.id}.feedback`
  );
  return (
    <div className={styles.outer}>
      <div className={styles.infoblock}>
        <h2 className={styles.header}>{analysis.name}</h2>
        <div className={styles.container}>
          <Stack direction="row" gap={2}>
            <AnalysisTooltip name={analysis.name} />
            <AnalysisBadge status={status} {...result} />
            {showModalButton ? (
              <>
                <SiftModal
                  context={{
                    analysisId: analysis.id,
                    investigationId: investigation.id,
                    show: showModal,
                    onClose: () => setShowModal(false),
                  }}
                />
                <Button
                  variant="secondary"
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  Open As Modal
                </Button>
              </>
            ) : null}
          </Stack>
          {result.successful && result.interesting && (
            <FeedbackButtons
              feedback={feedback}
              setFeedback={setFeedback}
              context={{ investigationID: investigation.id, analysisID: analysis.id, analysisName: analysis.name }}
              feedbackText="Is this analysis useful?"
            />
          )}
        </div>
      </div>

      {status === 'skipped' && (
        <div className={styles.alert}>
          <Alert severity="info" title="Analysis was skipped">
            {analysis.result.message}
          </Alert>
        </div>
      )}

      {status === 'finished' && !result.successful && (
        <div className={styles.alert}>
          <Alert severity="error" title="Analysis failed">
            {analysis.result.message}
          </Alert>
        </div>
      )}

      {status === 'finished' && result.successful && (
        <>
          <CustomisedMessage analysis={analysis} />
          {/* This next div is required because some custom details components */}
          {/* (especially scenes) set min-height: 100% which means they overflow */}
          {/* the parent, because we also have the message in the same container. */}
          <div className={styles.details}>
            {/* Wrap the custom details in an error boundary since they deal with unstructured */}
            {/* JSON which may fail to look as expected. This saves each individual component from */}
            {/* having to handle errors and ensures they're presented in the same way.*/}
            <ErrorBoundary dependencies={[investigation.id, analysis.id]}>
              {({ error }) => {
                if (error) {
                  return (
                    <Alert title="Error showing check results">
                      <p>There was an error showing the results of this check.</p>
                      <pre>
                        {error.message}
                        {error.stack && `\n\n${error.stack}`}
                      </pre>
                    </Alert>
                  );
                }
                return (
                  <AnalysisDetails
                    analysis={analysis}
                    investigation={investigation}
                    datasources={investigation.datasources}
                  />
                );
              }}
            </ErrorBoundary>
          </div>
        </>
      )}
    </div>
  );
}

function convertToTimeZone(originalDate: Date, targetTimeZone: string): string {
  const options: Intl.DateTimeFormatOptions = {
    hour12: false,
    timeZone: targetTimeZone,
    hour: 'numeric',
    minute: 'numeric',
  };
  return originalDate.toLocaleString('en-US', options);
}

const getStyles = (theme: GrafanaTheme2) => {
  return {
    message: css`
      font-size: 18px;
      margin: 8px 0px 24px 0px;
    `,
    outer: css`
      border-left: 1px solid ${theme.colors.border.weak};
      padding: 18px 18px 36px 18px;
      width: 90%;
      min-height: 800px;
      flex-direction: left;
      flex-grow: 1;
    `,
    infoblock: css`
      display: flex;
      gap: 16px;
      align-items: center;
    `,
    header: css`
      margin: 0;
    `,
    details: css`
      height: 100%;
    `,
    alert: css`
      margin: 24px 0px;
    `,
    container: css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    `,
  };
};
