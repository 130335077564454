import React, { useMemo } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Spinner, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';

import { useChecks, useFilteredAnalysesByStatus } from 'hooks';
import { Analysis, Investigation } from 'types';

import { AnalysisList } from './AnalysisList';
import { AnalysisResults } from './AnalysisResults';

interface InvestigationAnalysesProps {
  analyses: Analysis[];
  investigation: Investigation;
  refetchInvestigation: () => void;
}

export function InvestigationAnalyses({
  investigation,
  analyses,
  refetchInvestigation,
}: InvestigationAnalysesProps): JSX.Element {
  const styles = useStyles2(getStyles);
  const checks = useChecks(analyses);

  const { defaultAnalysis, interesting, passed, failed, running, skipped } = useFilteredAnalysesByStatus(
    analyses,
    checks
  );

  const [selectedAnalysisId, setSelectedAnalysisId] = useQueryParam(
    'analysis',
    withDefault(StringParam, defaultAnalysis)
  );
  const setCurrent = (current: string) => setSelectedAnalysisId(current, 'replaceIn');
  const selectedAnalysis = useMemo(
    () => analyses.find((a) => a.id === selectedAnalysisId) ?? analyses[0],
    [analyses, selectedAnalysisId]
  );

  if (investigation.status === 'pending' || selectedAnalysisId === undefined || selectedAnalysis === undefined) {
    setTimeout(() => {
      refetchInvestigation();
    }, 5000);
    return (
      <div className={styles.empty}>
        <Spinner inline={true} />
        Running investigation, please wait...
      </div>
    );
  }

  return (
    <div className={styles.outer}>
      <AnalysisList
        interesting={interesting}
        running={running}
        completed={passed}
        failed={failed}
        skipped={skipped}
        selectedAnalysisId={selectedAnalysisId}
        setSelectedAnalysisId={setCurrent}
      />
      <AnalysisResults
        key={`${investigation.id}.${selectedAnalysis.id}`}
        investigation={investigation}
        analysis={selectedAnalysis}
      />
    </div>
  );
}

const getStyles = (theme: GrafanaTheme2) => {
  return {
    outer: css`
      display: flex;
      background-color: ${theme.colors.background.primary};
      width: 100%;
      border: 1px solid ${theme.colors.border.weak};
    `,
    empty: css`
      display: flex;
      justfy-content: center;
      gap: 20px;
      padding: 30px;
      font-size: 16px;
      border: 1px solid ${theme.colors.border.weak};
      min-height: 200px;
    `,
  };
};
