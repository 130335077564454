import React from 'react';
import { useLocalStorage } from 'react-use';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, Icon, Stack, Tooltip, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

import { useFeature } from 'hooks';
import useRudderStack, { RudderstackEvents } from 'hooks/useRudderstack';

// `useFeedback` hook manages the feedback state using local storage.
// @param key - the key to store the feedback state in local storage.
// returns the feedback state saved in local storage and a setter function to update the feedback state.
export const useFeedback = (key: string) => {
  return useLocalStorage<boolean | undefined>(key);
};

interface FeedbackButtonsProps {
  feedback: boolean | undefined;
  setFeedback: (useful: boolean) => void;
  context: { [key: string]: any };
  feedbackText: string;
}

const FeedbackButtons = ({ feedback, setFeedback, context, feedbackText }: FeedbackButtonsProps) => {
  const styles = useStyles2(getStyles);
  const trackRudderstackEvent = useRudderStack();
  const feedbackEnabled = useFeature('SIFTDev');
  if (!feedbackEnabled) {
    return null;
  }
  return (
    <Tooltip content={feedbackText} placement="top">
      <Stack direction="row">
        <Button
          variant="secondary"
          disabled={feedback !== undefined}
          onClick={() => {
            trackRudderstackEvent(RudderstackEvents.UsefulSiftThumbsUp, {
              ...context,
            });
            setFeedback(true);
          }}
        >
          <Icon name="thumbs-up" size="sm" className={feedback === true ? styles.thumbsupActive : styles.thumbsup} />
        </Button>
        <Button
          variant="secondary"
          disabled={feedback !== undefined}
          fill={feedback === false ? 'solid' : undefined}
          onClick={() => {
            trackRudderstackEvent(RudderstackEvents.UsefulSiftThumbsDown, context);
            setFeedback(false);
          }}
        >
          <Icon
            name="thumbs-up"
            className={feedback === false ? styles.thumbsdownActive : styles.thumbsdown}
            size="sm"
          />
        </Button>
      </Stack>
    </Tooltip>
  );
};

export default FeedbackButtons;

const getStyles = (theme: GrafanaTheme2) => {
  const baseThumbStyle = css`
    display: flex;
    align-items: center;
    gap: 8px;
  `;

  return {
    thumbsup: baseThumbStyle,
    thumbsupActive: css`
      ${baseThumbStyle};
      color: ${theme.colors.text.maxContrast};
    `,
    thumbsdown: css`
      ${baseThumbStyle};
      transform: scale(-1, -1);
    `,
    thumbsdownActive: css`
      ${baseThumbStyle};
      transform: scale(-1, -1);
      color: ${theme.colors.text.maxContrast};
    `,
  };
};
