import React from 'react';

import { Icon, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

export const SearchSuffix = () => {
  const styles = useStyles2(getStyles);
  return (
    <div className={styles.suffixContainer}>
      <Icon name="keyboard" />/
    </div>
  );
};

const getStyles = () => ({
  suffixContainer: css`
    height: 32px;
    display: flex;
    flex-start: flex-end;
    align-items: center;
    gap: 4px;
    opacity: 0.9;
  `,
});
