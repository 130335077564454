import React, { useState } from 'react';
import { useFieldArray, UseFormRegister } from 'react-hook-form';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, Field, HorizontalGroup, IconButton, Input, useStyles2, VerticalGroup } from '@grafana/ui';

import { css } from '@emotion/css';

import { validateLabel } from 'components/FormFields/validators';
import { FullFormModel } from 'types';

interface CustomLabelsProps {
  control: any;
  getValues: any;
  register: UseFormRegister<FullFormModel>;
  onChange?: (values: any) => void;
}

export function CustomLabels({ getValues, control, register, onChange }: CustomLabelsProps): JSX.Element | null {
  const styles = useStyles2(getStyles);
  const [render, setRender] = useState(0);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'labels',
  });

  return (
    <div className={styles.container}>
      <VerticalGroup spacing="sm">
        <HorizontalGroup>
          <div className={styles.customLabels}>Custom Labels</div>
          <IconButton
            name="info-circle"
            size="sm"
            tooltip="Add custom key-value labels to your forecast. These labels will appear on the series returned by the grafanacloud-ml-metrics datasource and can be used as selectors in queries."
            tooltipPlacement="top"
            onClick={(e) => {
              e.preventDefault();
            }}
          />
        </HorizontalGroup>
        {fields.map((lbl, i) => {
          return (
            <LabelField
              key={lbl.id}
              onChange={() => {
                setRender(render + 1);
                if (typeof onChange === 'function') {
                  onChange(getValues);
                }
              }}
              getValues={getValues}
              register={register}
              index={i}
              remove={remove}
            />
          );
        })}
        <div className={styles.addButton}>
          <Button icon="plus-circle" variant="secondary" onClick={() => append({ prop: '', value: '' })}>
            Add Label
          </Button>
        </div>
      </VerticalGroup>
    </div>
  );
}

interface LabelFieldProps {
  getValues: any;
  index: number;
  onChange: () => void;
  register: UseFormRegister<FullFormModel>;
  remove: any;
}

function LabelField({ onChange, getValues, register, index, remove }: LabelFieldProps) {
  const styles = useStyles2(getStyles);
  const [labelName, setLabelName] = useState(getValues(`labels.${index}.prop`) ?? '');

  const { invalid, errorMessage } = validateLabel(labelName);
  const { onChange: onLabelChange } = register(`labels.${index}.prop`);

  return (
    <Field invalid={invalid} error={errorMessage}>
      <div className={styles.fieldContainer}>
        <Input
          {...register(`labels.${index}.prop`)}
          placeholder="Enter label name..."
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            setLabelName(e.currentTarget.value);
            onLabelChange(e);
            onChange();
          }}
          autoFocus
        />
        <div className={styles.promptBox}>=</div>
        <Input {...register(`labels.${index}.value`)} placeholder="Enter label value..." />
        <div className={styles.iconBox}>
          <IconButton
            aria-label="Remove label"
            name="trash-alt"
            onClick={(e) => {
              e.preventDefault();
              onChange();
              remove(index);
            }}
          />
        </div>
      </div>
    </Field>
  );
}

function getStyles(theme: GrafanaTheme2) {
  return {
    container: css`
      margin-bottom: 20px;
    `,
    customLabels: css`
      font-size: 12px;
      font-weight: 500;
    `,
    fieldContainer: css`
      display: flex;
    `,
    addButton: css`
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding-right: 5px;
    `,
    input: css`
      padding: 10px;
      height: 30px;
      outline-color: ${theme.colors.border.medium};
      width: 170px;
    `,
    promptBox: css`
      background-color: ${theme.colors.background.secondary};
      width: 30px;
      height: 30px;
      text-align: center;
      padding-top: 3px;
    `,
    iconBox: css`
      background-color: ${theme.colors.background.secondary};
      width: 30px;
      height: 30px;
      text-align: center;
      padding: 7px 0px 0px 4px;
    `,
  };
}
